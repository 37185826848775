import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import _set from 'lodash/fp/set';

import { initialState, initialStateAssetLabel, initialStateVariables } from './initialState';
import type { AssetLabelState, LabelState } from './types';

import { type Asset } from '../../__generated__/globalTypes';
import { xdate } from '../../helpers/xdate';

const sliceAssetLabel = createSlice({
  initialState,
  name: 'asset-label',
  reducers: {
    ADD_NON_SUBMITTED_VIEWED_ASSET(state, action: PayloadAction<string>) {
      const nonSubmittedViewedAssetIds = [...state.nonSubmittedViewedAssetIds, action.payload];
      return {
        ...state,
        nonSubmittedViewedAssetIds,
      };
    },
    ADD_REVIEWED_ASSET(state, { payload }: PayloadAction<string>) {
      state.reviewedAssets = state.reviewedAssets.concat(payload);
    },
    ASSET_INITIALIZE() {
      return initialState;
    },
    ASSET_LABEL_INITIALIZE(state) {
      return { ...state, initialStateVariables };
    },
    ASSET_UPDATE(state, action: PayloadAction<Partial<Asset>>) {
      return {
        ...state,
        asset: action.payload,
      };
    },
    LABELED_ASSETS_ADD(state, action: PayloadAction<string>) {
      state.labeledAssets = [...state.labeledAssets, action.payload];
    },
    LABELED_ASSETS_RESET(state) {
      state.labeledAssets = [];
    },
    LABEL_FETCHED(state) {
      return {
        ...state,
        labelHasBeenFetchedAt: xdate().format(),
      };
    },
    LABEL_INITIALIZE(state) {
      return {
        ...state,
        ...initialStateAssetLabel,
      };
    },
    LABEL_RESET(state) {
      return {
        ...state,
        label: {},
      };
    },
    LABEL_UPDATE(state, action: PayloadAction<LabelState>) {
      return {
        ...state,
        label: { ...state.label, ...action.payload },
      };
    },
    REMOVE_NON_SUBMITTED_VIEWED_ASSET(state, action: PayloadAction<string>) {
      const nonSubmittedViewedAssetIds = state.nonSubmittedViewedAssetIds.filter(
        id => id !== action.payload,
      );
      return {
        ...state,
        nonSubmittedViewedAssetIds,
      };
    },
    UPDATE_FIELD(state, action) {
      return _set(action.payload.path, action.payload.value, state) as unknown as AssetLabelState;
    },
    VIEWED_ASSETS_ADD(state, action: PayloadAction<string>) {
      const newViewedAssetIds = [...state.viewedAssetIds, action.payload];
      return {
        ...state,
        viewedAssetIds: newViewedAssetIds,
      };
    },
  },
});

export const {
  ADD_NON_SUBMITTED_VIEWED_ASSET,
  ADD_REVIEWED_ASSET,
  ASSET_INITIALIZE,
  ASSET_LABEL_INITIALIZE,
  ASSET_UPDATE,
  LABEL_FETCHED,
  LABEL_INITIALIZE,
  LABEL_RESET,
  LABEL_UPDATE,
  LABELED_ASSETS_ADD,
  LABELED_ASSETS_RESET,
  REMOVE_NON_SUBMITTED_VIEWED_ASSET,
  UPDATE_FIELD,
  VIEWED_ASSETS_ADD,
} = sliceAssetLabel.actions;

export default sliceAssetLabel.reducer;

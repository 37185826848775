/*
 * This file was created to patch the Rectangle class from leaflet to be able to support rectangles on a rotated map
 * So the idea in our codebase is to not use the Rectangle class from leaflet but to use our own KiliRectangle class
 */
import L from 'leaflet';

/* eslint-disable no-underscore-dangle */

class KiliRectangle extends L.Polygon {
  _checkRectangle: boolean;

  _initMap: L.Map;

  static isRectangle(latLngs: L.LatLng[], map: L.Map): boolean {
    // Check if there are exactly four coordinates
    if (latLngs.length !== 4) {
      return false;
    }

    const projectedLatLngs = latLngs.map(latLng => map.project(latLng));

    // Calculate the vectors between consecutive points
    const vectors = [
      [
        projectedLatLngs[1].x - projectedLatLngs[0].x,
        projectedLatLngs[1].y - projectedLatLngs[0].y,
      ],
      [
        projectedLatLngs[2].x - projectedLatLngs[1].x,
        projectedLatLngs[2].y - projectedLatLngs[1].y,
      ],
      [
        projectedLatLngs[3].x - projectedLatLngs[2].x,
        projectedLatLngs[3].y - projectedLatLngs[2].y,
      ],
      [
        projectedLatLngs[0].x - projectedLatLngs[3].x,
        projectedLatLngs[0].y - projectedLatLngs[3].y,
      ],
    ];

    // Check if the angles between consecutive vectors are right angles
    for (let i = 0; i < 4; i += 1) {
      const dotProduct =
        vectors[i][0] * vectors[(i + 1) % 4][0] + vectors[i][1] * vectors[(i + 1) % 4][1];
      if (Math.abs(dotProduct) > 1e-6) {
        // Tolerance for floating point errors
        return false;
      }
    }

    return true;
  }

  constructor(
    latLngs: L.RectangleLatLngs,
    map: L.Map,
    options?: L.PolylineOptions,
    checkRectangle = false,
  ) {
    if (checkRectangle && !KiliRectangle.isRectangle(latLngs, map)) {
      throw new Error('The provided coordinates do not form a rectangle');
    }
    super(latLngs, options);
    this._initMap = map;
    // Even if we do not force the check, during the creation of the rectangle, if the shape is already a correct rectangle, we need to keep later checking if it stays a rectangle
    this._checkRectangle = checkRectangle || KiliRectangle.isRectangle(latLngs, map);
  }

  setLatLngs(latLngs: L.RectangleLatLngs): this {
    if (this._checkRectangle && !KiliRectangle.isRectangle(latLngs, this._map || this._initMap)) {
      throw new Error('The provided coordinates do not form a rectangle');
    }
    super.setLatLngs(latLngs);
    return this;
  }

  getLatLngs(): L.LatLng[][] {
    return super.getLatLngs() as L.LatLng[][];
  }
}

L.KiliRectangle = KiliRectangle;

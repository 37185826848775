import {
  LabelVersion,
  type Annotation,
  type AnnotationJobCounter,
  type AnnotationNamesJob,
  type JobAnnotation,
  type Jobs,
  type JsonResponse,
  type ObjectAnnotation,
} from '@kili-technology/types';
import { get as _get } from 'lodash';
import { batch } from 'react-redux';

import {
  InputType,
  type LabelType,
  type VideoClassificationAnnotation,
  type VideoObjectDetectionAnnotation,
  type VideoTranscriptionAnnotation,
} from '@/__generated__/globalTypes';
import { ANNOTATIONS, MID } from '@/components/InterfaceBuilder/FormInterfaceBuilder/constants';
import { frameResponseContainsKeyFrameForObjects } from '@/components/asset-ui/Frame/helpers';
import { isVideoObjectDetectionAnnotation } from '@/graphql/annotations/helpers/data/validators/isVideoObjectDetectionAnnotation';
import { type ObjectDetectionAnnotations } from '@/redux/jobs/types';
import { createObjectInFrameResponse } from '@/services/jobs/create';
import { getResponsesToSet, type KiliAnnotation } from '@/services/jobs/setResponse';
import {
  updateObjectClassInFrameResponse,
  updateObjectInFrameResponse,
} from '@/services/jobs/update';
import { type AppThunk, store } from '@/store';
import {
  labelInterfaceUpdateField,
  removeAllSelectedObjectIds,
  replaceAllWithSelectedObjectId,
  useStore,
} from '@/zustand';
import { useHistoryStore } from '@/zustand-history';

import { updateFrameResponses } from './actions/updateFrameResponses';
import { updateFrameResponsesWithoutCloning } from './actions/updateFrameResponsesWithoutCloning';
import { convertJsonResponseToFrameResponses } from './helpers';
import {
  type LabelFrameSelectRangeObject,
  type LabelFrameSetResponsePayload,
  type LabelFrameUpdateCurrentFramePayload,
} from './types';

import { assetLabelLabelType } from '../asset-label/selectors';
import { getAllObjects } from '../jobs/helpers';
import { frameResponsesObjectsInfo } from '../jobs/selectors';
import { labelFramesFrameResponses } from '../label-frames/selectors';
import { LABEL_FRAMES_SET_RESPONSES } from '../label-frames/slice';
import { projectInputType } from '../selectors';

export const initializeJobCategoriesToMids = (
  jobs: Jobs,
  labelType: LabelType,
  responses: JsonResponse[],
) => {
  const { setJobCategoriesToMids, initJobCategoriesToMids } = useStore.getState().labelFrame;
  const newAnnotations: Annotation[] = [];

  if (responses['0']?.ANNOTATION_JOB_COUNTER && responses['0']?.ANNOTATION_NAMES_JOB) {
    initJobCategoriesToMids({
      ANNOTATION_JOB_COUNTER: responses['0'].ANNOTATION_JOB_COUNTER as AnnotationJobCounter,
      ANNOTATION_NAMES_JOB: responses['0']?.ANNOTATION_NAMES_JOB as AnnotationNamesJob,
    });
  } else {
    responses.forEach(item => {
      const responseToSet = getResponsesToSet(jobs, labelType, item);
      responseToSet.forEach(itm => {
        const { annotations } = itm.jobResponse as JobAnnotation;
        if (annotations) {
          annotations.forEach(ann => {
            const foundItem = newAnnotations.find(newAnn => newAnn.mid === ann.mid);
            if (!foundItem) newAnnotations.push(ann);
          });
        }
      });
    });
    newAnnotations?.forEach(anno => {
      setJobCategoriesToMids({
        category: anno.categories[0].name,
        jobName: anno.jobName as string,
        mid: anno.mid,
        name:
          jobs?.[anno.jobName as string]?.content?.categories?.[anno.categories[0].name]?.name ??
          '',
      });
    });
  }
};

export const initializeJobCategoriesToMidsSplit = (
  jobs: Jobs,
  videoAnnotations: (
    | VideoClassificationAnnotation
    | VideoObjectDetectionAnnotation
    | VideoTranscriptionAnnotation
  )[],
) => {
  const { initJobCategoriesToMids } = useStore.getState().labelFrame;
  let annotationNamesJob = {} as AnnotationNamesJob;
  let annotationJobCounter = {} as AnnotationJobCounter;
  if (!videoAnnotations?.length) {
    initJobCategoriesToMids({
      ANNOTATION_JOB_COUNTER: annotationJobCounter,
      ANNOTATION_NAMES_JOB: annotationNamesJob,
    });
  } else {
    const videoAnnotationsToAdd = videoAnnotations.filter(isVideoObjectDetectionAnnotation);

    videoAnnotationsToAdd.forEach(annotation => {
      const lowerCaseName =
        jobs?.[annotation.job]?.content?.categories?.[annotation.category]?.name ?? '';

      const currentCounter: number =
        annotationJobCounter?.[annotation.job]?.[annotation.category] ?? 0;
      const counter = annotation.name
        ? Number(annotation.name?.replace(lowerCaseName, '') ?? 0)
        : currentCounter + 1;

      annotationNamesJob = {
        ...annotationNamesJob,
        [annotation.mid]: `${lowerCaseName} ${counter}`,
      };

      if (counter <= currentCounter && annotation.name) return;

      annotationJobCounter = {
        ...annotationJobCounter,
        [annotation.job]: {
          ...annotationJobCounter[annotation.job],
          [annotation.category]: counter,
        },
      };
    });

    initJobCategoriesToMids({
      ANNOTATION_JOB_COUNTER: annotationJobCounter,
      ANNOTATION_NAMES_JOB: annotationNamesJob,
    });
  }
};

export const labelFrameSet = (payload: LabelFrameSetResponsePayload): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();
    const labelType = assetLabelLabelType(state);
    const { labelResponse } = payload;
    const frameResponses = convertJsonResponseToFrameResponses(labelResponse, labelType);
    batch(() => {
      dispatch(LABEL_FRAMES_SET_RESPONSES({ frameResponses }));
      dispatch(updateFrameResponsesWithoutCloning({ frameResponses }));
    });
  };
};

export const createAnnotationInFrameResponses = (
  currentFrame: number,
  propagation: number,
  newAnnotation: KiliAnnotation,
) => {
  const responses = store.getState().labelFrames.frameResponses;
  const newFrameJsonResponse = createObjectInFrameResponse(
    currentFrame,
    propagation,
    newAnnotation,
    responses,
  );
  return updateFrameResponses({ frameResponses: newFrameJsonResponse });
};

const updateAnnotationInFrameResponses = (
  currentFrame: number,
  newAnnotation: ObjectAnnotation,
) => {
  const responses = store.getState().labelFrames.frameResponses;
  const newResponses = updateObjectInFrameResponse(currentFrame, newAnnotation, responses);
  return updateFrameResponses({ frameResponses: newResponses });
};

export const updateAnnotation = (
  newAnnotation: Omit<ObjectAnnotation, 'jobName'> & { jobName: string },
): AppThunk => {
  return async (dispatch, getState) => {
    const { jobName, mid } = newAnnotation;
    const responses = labelFramesFrameResponses(getState());
    const { frame: currentFrame } = useStore.getState().labelFrame;

    const previousAnnotation = (
      responses[currentFrame][jobName] as ObjectDetectionAnnotations
    )?.annotations?.find(annotation => annotation.mid === mid);

    if (previousAnnotation === undefined) {
      throw new Error(
        `Unable to find annotation with mid ${mid} in job ${jobName} of frame ${currentFrame}`,
      );
    }

    const action = {
      name: 'updateAnnotation',
      redo: () => {
        dispatch(
          updateAnnotationInFrameResponses(currentFrame, {
            ...newAnnotation,
            labelVersion: LabelVersion.DEFAULT, // If the annotation was a prediction, we switch it to default as we updated it manually
          }),
        );
      },
      undo: () => {
        dispatch(updateAnnotationInFrameResponses(currentFrame, previousAnnotation));
      },
    };

    action.redo();
    useHistoryStore.getState().history.addAction(action);
  };
};

export const changeAnnotationClassInFrameResponses = (newAnnotation: KiliAnnotation) => {
  const responses = labelFramesFrameResponses(store.getState());
  const objectsInfo = frameResponsesObjectsInfo(store.getState());

  const newResponses = updateObjectClassInFrameResponse(newAnnotation, responses, objectsInfo);
  return updateFrameResponses({ frameResponses: newResponses });
};

export const toggleKeyFrameOnCurrentFrame = (mid: string): AppThunk => {
  return async (dispatch, getState) => {
    const responses = labelFramesFrameResponses(getState());
    const { frame: currentFrame } = useStore.getState().labelFrame;
    const keyFrames = Object.entries(responses)
      .filter(([_, response]) => frameResponseContainsKeyFrameForObjects(response, [mid]))
      .map(([frame, _]) => parseInt(frame, 10));

    const annotations = getAllObjects(responses)?.filter(
      annotation => _get(annotation, MID) === mid,
    );
    const jobName = annotations?.length > 0 ? annotations[0].jobName : null;
    if (!jobName) return;

    const currentFrameAnnotation = (
      _get(responses[currentFrame], [jobName, ANNOTATIONS], []) as ObjectAnnotation[]
    ).find(annotation => annotation[MID] === mid);

    if (
      !currentFrameAnnotation ||
      (keyFrames.length === 1 && keyFrames.includes(currentFrame)) ||
      currentFrame === keyFrames[0]
    )
      return;

    const newAnnotation = {
      ...currentFrameAnnotation,
      isKeyFrame: !keyFrames.includes(currentFrame),
    } as KiliAnnotation;
    const previousAnnotation = {
      ...currentFrameAnnotation,
    } as KiliAnnotation;

    const action = {
      name: 'toggleAnnotationKeyFrame',
      redo: () => {
        dispatch(updateAnnotationInFrameResponses(currentFrame, newAnnotation));
      },
      undo: () => {
        dispatch(updateAnnotationInFrameResponses(currentFrame, previousAnnotation));
      },
    };

    dispatch(updateAnnotationInFrameResponses(currentFrame, newAnnotation));
    useHistoryStore.getState().history.addAction(action);
  };
};

export const resetFrameStateBeforeGoingToNextAsset = (): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();
    const inputType = projectInputType(state);
    if (inputType !== InputType.VIDEO) return;
    useStore.getState().labelFrame.resetFrame();
  };
};

export const setSelectedFrame = ({
  frame,
  selectedFrames: newSelectedFrames,
}: LabelFrameUpdateCurrentFramePayload): AppThunk => {
  return () => {
    useStore
      .getState()
      .labelFrame.setSelectedFrames({ frame, selectedFrames: newSelectedFrames || {} });
  };
};

export const selectRangeObject = ({
  mid,
  isSelectingSeveralObjects,
}: LabelFrameSelectRangeObject) => {
  const {
    labelInterface: { selectedObjectIds },
  } = useStore.getState();
  const isSelectingANewObject = !selectedObjectIds.includes(mid);
  if (!isSelectingSeveralObjects) {
    replaceAllWithSelectedObjectId({ mid });
    return;
  }
  removeAllSelectedObjectIds();
  if (mid && selectedObjectIds.length === 1 && !isSelectingANewObject) {
    return;
  }
  const newSelectedRangeObjects = isSelectingANewObject
    ? selectedObjectIds.concat([mid])
    : selectedObjectIds.filter(id => id !== mid);
  labelInterfaceUpdateField({ path: 'selectedObjectIds', value: newSelectedRangeObjects });
};

export const resetSelectedFrames = (): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();
    const inputType = projectInputType(state);
    if (inputType !== InputType.VIDEO) return;
    useStore.getState().labelFrame.resetSelectedFrames();
  };
};

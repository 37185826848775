import { createListenerMiddleware } from '@reduxjs/toolkit';

import { updateField as zustandUpdateField } from '@/zustand';

import { LABEL_UPDATE } from './slice';

export const assetLabelListenerMiddleware = createListenerMiddleware();

assetLabelListenerMiddleware.startListening({
  actionCreator: LABEL_UPDATE,
  effect: action => {
    zustandUpdateField({
      key: 'latestSavedLabelId',
      sliceName: 'splitLabel',
      value: action.payload.id,
    });
  },
});

export const ACCESS_DENIED = 'accessDenied';
export const COPILOT_MAX_CALLS = 'copilotMaxCalls';
export const DEACTIVATED_USER = 'deactivatedUser';
export const DUPLICATED_EXTERNAL_ID_ERROR = 'duplicatedExternalId';
export const EMPTY_CATEGORY_NAME = 'emptyCategoryName';
export const JSON_MALFORMED = 'jsonMalformed';
export const LICENSE_EXPIRED_ERROR = 'licenseExpired';
export const LICENSE_LIMITATION = 'licenseLimitation';
export const LICENSE_NUMBER_OF_LABELED_ASSETS = 'numberOfLabeledAssetsLicense';
export const LICENSE_NUMBER_OF_SEATS = 'numberOfSeatsLicense';
export const INVALID_OPERATION = 'Invalid operation';
export const ISSUE_LABEL_AUTHOR_REMOVED = 'issueLabelAuthorRemoved';
export const MAXIMUM_NUMBER_OF_PROJECT_USERS = 'maxProjectUsers';
export const MAX_PROJECT_SIZE_ERROR = 'maxProjectSize';
export const NO_ACCESS_RIGHT_ERROR = 'noAccessRights';
export const NO_RESULT = 'query returned no result';
export const PASSWORD_SHOULD_MATCH_ERROR = 'passwordsShouldMatch';
export const UNEXPECTED_RETRIEVING = 'unexpectedRetrieving';
export const USER_SUSPENDED_IN_ORGANIZATION = 'userSuspendedInOrg';
export const USER_SUSPENDING_SELF = 'suspendingSelf';
export const WRONG_PASSWORD = 'wrongPassword';
export const ASSET_ALREADY_LOCKED = 'assetAlreadyLocked';

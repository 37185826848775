/*
 This files was created to patch the Draw.Rectangle class from leaflet-draw to be able to support the rotation of the map when drawing a rectangle
 */

import L from 'leaflet';
import '../Path/KiliRectangle';

/* eslint-disable no-underscore-dangle */

L.Draw.Rectangle.include({
  _drawShape(this: L.Draw.Rectangle, latlng: L.LatLng) {
    const startContainerPoint = this._map.latLngToContainerPoint(this._startLatLng);
    const endContainerPoint = this._map.latLngToContainerPoint(latlng);

    // Then we need to get the 4 corners of the rectangle as container points. We have to put first the point with the
    // smallest x and biggest y values, then the point with the smallest x and the smallest y value, then the point with
    // the biggest x and the smallest y value, and finally the point with the biggest x and the biggest y value.
    const allRectanglePointsAsContainerPoints = [
      L.point(
        Math.min(startContainerPoint.x, endContainerPoint.x),
        Math.max(startContainerPoint.y, endContainerPoint.y),
      ),
      L.point(
        Math.min(startContainerPoint.x, endContainerPoint.x),
        Math.min(startContainerPoint.y, endContainerPoint.y),
      ),
      L.point(
        Math.max(startContainerPoint.x, endContainerPoint.x),
        Math.min(startContainerPoint.y, endContainerPoint.y),
      ),
      L.point(
        Math.max(startContainerPoint.x, endContainerPoint.x),
        Math.max(startContainerPoint.y, endContainerPoint.y),
      ),
    ];

    const allRectanglePoints = allRectanglePointsAsContainerPoints.map(point =>
      this._map.containerPointToLatLng(point),
    ) as L.RectangleLatLngs;

    if (!this._shape) {
      this._shape = new L.KiliRectangle(
        allRectanglePoints,
        this._map,
        this.options.shapeOptions,
        true,
      );
      this._map.addLayer(this._shape);
    } else {
      this._shape.setLatLngs(allRectanglePoints);
    }
  },

  _fireCreatedEvent(this: L.Draw.Rectangle) {
    const rectangle = new L.KiliRectangle(
      this._shape.getLatLngs()[0],
      this._map,
      this.options.shapeOptions,
      true,
    );
    L.Draw.SimpleShape.prototype._fireCreatedEvent.call(this, rectangle);
  },
});

import React, { type ComponentProps } from 'react';

import '@/components/asset-ui/Leaflet/LeafletLibLoader';
import { AssetsProvider } from '@/components/AssetsProvider/AssetsProvider';

import { RootRoute } from './RootPaths';

const UserDetail = React.lazy(() => import('../organization/Users/UserDetail'));
const Account = React.lazy(() => import('../account/Account'));
const Explore = React.lazy(() => import('../project/explore'));

const IntegrationDetail = React.lazy(
  () => import('../organization/Integrations/IntegrationDetail'),
);
const IntegrationCreate = React.lazy(
  () => import('../organization/Integrations/IntegrationCreate'),
);
const CreateProject = React.lazy(
  () => import('../projects/create-project-wizard/CreateProjectWizard'),
);
const ForgotPassword = React.lazy(() => import('../auth/ForgotPassword'));
const LabelProject = React.lazy(() => import('../projects/label/LabelProject'));
const LlmInstrFollowingLabelProject = React.lazy(
  () => import('../projects/label/LlmInstrFollowingLabelProject'),
);
const NewDataConnection = React.lazy(() => import('../projects/data-connection'));
const DataConnectionDetails = React.lazy(
  () => import('../projects/view/settings/ConnectionsData/components/ConnectionDetails'),
);
const Organization = React.lazy(() => import('../organization/Organization'));
const OrganizationMembers = React.lazy(() => import('../organization/Users'));
const ProjectLists = React.lazy(() =>
  import('../projects/ProjectLists').then(module => ({ default: module.ProjectLists })),
);
const DemoProject = React.lazy(() => import('../projects/DemoProject'));
const ProjectModule = React.lazy(() => import('../projects/ProjectModule'));
const Register = React.lazy(() => import('../auth/Register'));
const SignIn = React.lazy(() => import('../auth/SignIn'));
const SignInCallback = React.lazy(() => import('../auth/SignInCallback'));
const SignUp = React.lazy(() => import('../auth/SignUp'));
const OrganizationList = React.lazy(() => import('../organization/OrganizationList'));
const ProjectSettings = React.lazy(() => import('../projects/view/settings/ProjectSettings'));

const rootComponents = {
  [RootRoute.LABEL_INSTR_FOLLOWING_ROUTE]: {
    component: (props: ComponentProps<typeof LlmInstrFollowingLabelProject>): JSX.Element => (
      <LlmInstrFollowingLabelProject {...props} />
    ),
  },
  [RootRoute.REVIEW_INSTR_FOLLOWING_ROUTE]: {
    component: (props: ComponentProps<typeof LlmInstrFollowingLabelProject>): JSX.Element => (
      <LlmInstrFollowingLabelProject {...props} />
    ),
  },
  [RootRoute.LABEL_ROUTE]: {
    component: (props: ComponentProps<typeof LabelProject>): JSX.Element => (
      <LabelProject {...props} />
    ),
  },
  [RootRoute.REVIEW_ROUTE]: {
    component: (props: ComponentProps<typeof LabelProject>): JSX.Element => (
      <LabelProject {...{ ...props, inReview: true }} />
    ),
  },
  [RootRoute.EXPLORE_ROUTE]: {
    component: (props: ComponentProps<typeof Explore>): JSX.Element => (
      <AssetsProvider>
        <Explore {...props} />
      </AssetsProvider>
    ),
  },
  [RootRoute.SIGNIN_ROUTE]: {
    component: (props: ComponentProps<typeof SignIn>): JSX.Element => <SignIn {...props} />,
  },
  [RootRoute.SIGNUP_ROUTE]: {
    component: (props: ComponentProps<typeof SignUp>): JSX.Element => <SignUp {...props} />,
  },
  [RootRoute.SIGNOUT_ROUTE]: {
    component: (props: ComponentProps<typeof SignIn>): JSX.Element => <SignIn {...props} />,
  },
  [RootRoute.FORGOT_PASSWORD_ROUTE]: {
    component: (props: ComponentProps<typeof ForgotPassword>): JSX.Element => (
      <ForgotPassword {...props} />
    ),
  },
  [RootRoute.BEGIN_PROJECT_CREATION_ROUTE]: {
    component: (props: ComponentProps<typeof CreateProject>): JSX.Element => (
      <CreateProject {...props} />
    ),
  },
  [RootRoute.ACCOUNT_ROUTE]: {
    component: (props: ComponentProps<typeof Account>): JSX.Element => <Account {...props} />,
  },
  [RootRoute.ORGANIZATION_LIST_ROUTE]: {
    component: (): JSX.Element => <OrganizationList />,
  },
  [RootRoute.ORGANIZATION_ROUTE]: {
    component: (): JSX.Element => <Organization />,
  },
  [RootRoute.ORGANIZATION_MEMBERS_ROUTE]: {
    component: (): JSX.Element => <OrganizationMembers />,
  },
  [RootRoute.INTEGRATION_DETAIL]: {
    component: (): JSX.Element => <IntegrationDetail />,
  },
  [RootRoute.INTEGRATION_CREATION]: {
    component: (): JSX.Element => <IntegrationCreate />,
  },
  [RootRoute.INTEGRATION_CREATION_TYPE]: {
    component: (): JSX.Element => <IntegrationCreate />,
  },
  [RootRoute.PROJECT_ROUTE]: {
    component: (props: ComponentProps<typeof ProjectModule>): JSX.Element => (
      <ProjectModule {...props} />
    ),
  },
  [RootRoute.DEMO_PROJECT_ROUTE]: {
    component: (): JSX.Element => <DemoProject />,
  },
  [RootRoute.LOGIN_SUCCESS]: {
    component: (props: ComponentProps<typeof SignInCallback>): JSX.Element => (
      <SignInCallback {...props} />
    ),
  },
  [RootRoute.NEW_PROJECT_STORAGE_CONNECTION]: {
    component: (props: ComponentProps<typeof NewDataConnection>): JSX.Element => (
      <NewDataConnection {...props} />
    ),
  },
  [RootRoute.PROJECT_STORAGE_CONNECTION]: {
    component: (props: ComponentProps<typeof DataConnectionDetails>): JSX.Element => (
      <DataConnectionDetails {...props} />
    ),
  },
  [RootRoute.USER_DETAIL_ROUTE]: {
    component: (): JSX.Element => <UserDetail />,
  },
  [RootRoute.REGISTER_ROUTE]: {
    component: (): JSX.Element => <Register />,
  },
  [RootRoute.ORGANIZATIONS_ROUTE]: {
    component: (): JSX.Element => <Organization />,
  },
  [RootRoute.PROJECT_DATA_TAB_ROUTE]: {
    component: (props: ComponentProps<typeof ProjectSettings>): JSX.Element => (
      <ProjectSettings {...props} />
    ),
  },
  [RootRoute.PROJECT_INTERFACE_TAB_ROUTE]: {
    component: (props: ComponentProps<typeof ProjectSettings>): JSX.Element => (
      <ProjectSettings {...props} />
    ),
  },
  [RootRoute.PROJECT_LISTS]: {
    component: (): JSX.Element => <ProjectLists />,
  },
};

export default rootComponents;

import { Input, MachineLearningTask, ModelName, Tool } from '@kili-technology/types';

import { InputType } from '../../../__generated__/globalTypes';
import { OCR } from '../../../constants/tools';
import { getTranslation } from '../../../i18n/i18n';

export type PossibleToolsForMLTasks = Partial<
  Record<
    MachineLearningTask,
    { mlTask: MachineLearningTask; size: number; values: (MachineLearningTask | Input | Tool)[] }
  >
>;

export const UNKNOWN = 'UNKNOWN';

export const translations: { [key: string]: string } = {
  [Input.CHECKBOX]: getTranslation('Input.CHECKBOX'),
  [Input.MULTI_DROPDOWN]: getTranslation('Input.MULTI_DROPDOWN'),
  [Input.RADIO]: getTranslation('Input.RADIO'),
  [Input.SINGLE_DROPDOWN]: getTranslation('Input.SINGLE_DROPDOWN'),
  [Input.TEXT_FIELD]: getTranslation('Input.TEXT_FIELD'),
  [Input.DATE]: getTranslation('Input.DATE'),
  [Input.NUMBER]: getTranslation('Input.NUMBER'),
  [InputType.AUDIO]: getTranslation('InputType.AUDIO'),
  [InputType.VIDEO]: getTranslation('InputType.VIDEO'),
  [InputType.VIDEO_LEGACY]: getTranslation('InputType.VIDEO_LEGACY'),
  [InputType.IMAGE]: getTranslation('InputType.IMAGE'),
  [InputType.PDF]: getTranslation('InputType.PDF'),
  [InputType.TEXT]: getTranslation('InputType.TEXT'),
  [InputType.LLM_RLHF]: getTranslation('InputType.LLM_RLHF'),
  [MachineLearningTask.CLASSIFICATION]: getTranslation('MachineLearningTask.CLASSIFICATION'),
  [MachineLearningTask.PAGE_LEVEL_CLASSIFICATION]: getTranslation(
    'MachineLearningTask.PAGE_LEVEL_CLASSIFICATION',
  ),
  [MachineLearningTask.NAMED_ENTITIES_RECOGNITION]: getTranslation(
    'MachineLearningTask.NAMED_ENTITIES_RECOGNITION',
  ),
  [MachineLearningTask.NAMED_ENTITIES_RELATION]: getTranslation(
    'MachineLearningTask.NAMED_ENTITIES_RELATION',
  ),
  [MachineLearningTask.OBJECT_DETECTION]: getTranslation('MachineLearningTask.OBJECT_DETECTION'),
  [MachineLearningTask.OBJECT_RELATION]: getTranslation('MachineLearningTask.OBJECT_RELATION'),
  [MachineLearningTask.POSE_ESTIMATION]: getTranslation('MachineLearningTask.POSE_ESTIMATION'),
  [MachineLearningTask.TRANSCRIPTION]: getTranslation('MachineLearningTask.TRANSCRIPTION'),
  [MachineLearningTask.PAGE_LEVEL_TRANSCRIPTION]: getTranslation(
    'MachineLearningTask.PAGE_LEVEL_TRANSCRIPTION',
  ),
  [ModelName.INTERACTIVE_SEGMENTATION]: getTranslation('ModelName.INTERACTIVE_SEGMENTATION'),
  [ModelName.TRACKING]: getTranslation('ModelName.TRACKING'),
  [Tool.MARKER]: getTranslation('Tool.MARKER'),
  [Tool.POLYGON]: getTranslation('Tool.POLYGON'),
  [Tool.POLYLINE]: getTranslation('Tool.POLYLINE'),
  [Tool.POSE]: getTranslation('Tool.POSE'),
  [Tool.RANGE]: getTranslation('Tool.RANGE'),
  [Tool.RECTANGLE]: getTranslation('Tool.RECTANGLE'),
  [Tool.SEMANTIC]: getTranslation('Tool.SEMANTIC'),
  [Tool.VECTOR]: getTranslation('Tool.VECTOR'),
  [UNKNOWN]: getTranslation('UNKNOWN'),
};

export const translationsNewDesign: { [key: string]: string } = {
  ...translations,
  CLASSIFICATION_DROPDOWN: getTranslation('CLASSIFICATION_DROPDOWN'),
  [Tool.SEMANTIC]: getTranslation('Tool.SEMANTIC_ALT'),
  [MachineLearningTask.CLASSIFICATION]: getTranslation('MachineLearningTask.CLASSIFICATION_ALT'),
  [MachineLearningTask.COMPARISON]: getTranslation('MachineLearningTask.COMPARISON'),
  [OCR]: getTranslation('OCR'),
  [MachineLearningTask.OBJECT_RELATION]: getTranslation('MachineLearningTask.OBJECT_RELATION_ALT'),
  [Input.RADIO]: getTranslation('Input.RADIO_ALT'),
  [Input.CHECKBOX]: getTranslation('Input.CHECKBOX_ALT'),
  [Input.MARKDOWN]: getTranslation('Input.MARKDOWN'),
  [Input.MULTI_DROPDOWN]: getTranslation('Input.MULTI_DROPDOWN_ALT'),
  [Input.SINGLE_DROPDOWN]: getTranslation('Input.SINGLE_DROPDOWN_ALT'),
  [Input.SELECT]: getTranslation('Input.SELECT'),
};

export const CAN_HAVE_CHILDREN = [
  MachineLearningTask.CLASSIFICATION,
  MachineLearningTask.NAMED_ENTITIES_RECOGNITION,
  MachineLearningTask.NAMED_ENTITIES_RELATION,
  MachineLearningTask.OBJECT_DETECTION,
  MachineLearningTask.OBJECT_RELATION,
  MachineLearningTask.POSE_ESTIMATION,
  Input.CHECKBOX,
  Input.RADIO,
  Input.SINGLE_DROPDOWN,
];

export const RELATION_TASKS = [
  MachineLearningTask.NAMED_ENTITIES_RELATION,
  MachineLearningTask.OBJECT_RELATION,
];

const CLASSIFICATION = {
  mlTask: MachineLearningTask.CLASSIFICATION,
  size: 6,
  values: [Input.RADIO, Input.CHECKBOX],
};
const CLASSIFICATION_DROPDOWN = {
  mlTask: MachineLearningTask.CLASSIFICATION,
  size: 6,
  values: [Input.SINGLE_DROPDOWN, Input.MULTI_DROPDOWN],
};
const TRANSCRIPTION = {
  mlTask: MachineLearningTask.TRANSCRIPTION,
  size: 12,
  values: [Input.TEXT_FIELD, Input.DATE, Input.NUMBER, Input.MARKDOWN],
};
const OBJECT_RELATION = {
  mlTask: MachineLearningTask.OBJECT_RELATION,
  size: 12,
  values: [MachineLearningTask.OBJECT_RELATION],
};
const NAMED_ENTITIES_RECOGNITION = {
  mlTask: MachineLearningTask.NAMED_ENTITIES_RECOGNITION,
  size: 12,
  values: [MachineLearningTask.NAMED_ENTITIES_RECOGNITION],
};
const NAMED_ENTITIES_RELATION = {
  mlTask: MachineLearningTask.NAMED_ENTITIES_RELATION,
  size: 12,
  values: [MachineLearningTask.NAMED_ENTITIES_RELATION],
};
const COMPARISON = {
  mlTask: MachineLearningTask.COMPARISON,
  size: 12,
  values: [Input.RADIO],
};

export const TOOLS_FOR_SUBJOB = {
  CLASSIFICATION,
  CLASSIFICATION_DROPDOWN,
  TRANSCRIPTION,
};
export const TOOLS_FOR_AUDIO = {
  CLASSIFICATION,
  CLASSIFICATION_DROPDOWN,
  TRANSCRIPTION,
};
export const TOOLS_FOR_FRAME = {
  CLASSIFICATION,
  CLASSIFICATION_DROPDOWN,
  OBJECT_DETECTION: {
    mlTask: MachineLearningTask.OBJECT_DETECTION,
    size: 12,
    values: [Tool.MARKER, Tool.RECTANGLE, Tool.POLYGON],
  },
  TRANSCRIPTION,
};

export const TOOLS_FOR_FRAME_SPLIT = {
  CLASSIFICATION,
  CLASSIFICATION_DROPDOWN,
  OBJECT_DETECTION: {
    mlTask: MachineLearningTask.OBJECT_DETECTION,
    size: 12,
    values: [Tool.MARKER, Tool.RECTANGLE, Tool.POLYGON, Tool.SEMANTIC],
  },
  TRANSCRIPTION,
};

export const TOOLS_FOR_IMAGE = {
  CLASSIFICATION,
  CLASSIFICATION_DROPDOWN,
  OBJECT_DETECTION: {
    mlTask: MachineLearningTask.OBJECT_DETECTION,
    size: 12,
    values: [
      Tool.RECTANGLE,
      Tool.MARKER,
      Tool.POLYGON,
      Tool.POLYLINE,
      Tool.POSE,
      Tool.SEMANTIC,
      Tool.VECTOR,
    ],
  },
  OBJECT_RELATION,
  TRANSCRIPTION,
};
export const TOOLS_FOR_PDF = {
  CLASSIFICATION,
  CLASSIFICATION_DROPDOWN,
  NAMED_ENTITIES_RECOGNITION,
  NAMED_ENTITIES_RELATION,
  OBJECT_DETECTION: {
    mlTask: MachineLearningTask.OBJECT_DETECTION,
    size: 12,
    values: [Tool.RECTANGLE],
  },
  TRANSCRIPTION,
};
export const TOOLS_FOR_TEXT = {
  CLASSIFICATION,
  CLASSIFICATION_DROPDOWN,
  NAMED_ENTITIES_RECOGNITION,
  NAMED_ENTITIES_RELATION,
  TRANSCRIPTION,
};
export const TOOLS_FOR_LLM_RLHF = {
  CLASSIFICATION,
  CLASSIFICATION_DROPDOWN,
  TRANSCRIPTION,
};

export const TOOLS_FOR_LLM_INSTR_FOLLOWING = {
  COMPARISON,
};

export const TOOLS_FOR_ML_TASKS = {
  [InputType.AUDIO]: TOOLS_FOR_AUDIO,
  [InputType.IMAGE]: TOOLS_FOR_IMAGE,
  [InputType.PDF]: TOOLS_FOR_PDF,
  [InputType.TEXT]: TOOLS_FOR_TEXT,
  [InputType.VIDEO]: TOOLS_FOR_FRAME,
  [InputType.LLM_RLHF]: TOOLS_FOR_LLM_RLHF,
  [InputType.LLM_INSTR_FOLLOWING]: TOOLS_FOR_LLM_INSTR_FOLLOWING,
};

export enum RelationObjectType {
  END_ENTITIES = 'endEntities',
  END_OBJECTS = 'endObjects',
  START_ENTITIES = 'startEntities',
  START_OBJECTS = 'startObjects',
}

export const ANNOTATIONS = 'annotations';
export const BEGIN_ID = 'beginId';
export const BEGIN_OFFSET = 'beginOffset';
export const CATEGORIES = 'categories';
export const OPTIONS = 'options';
export const CHILDREN = 'children';
export const COLOR = 'color';
export const CONFIDENCE = 'confidence';
export const CONTENT = 'content';
export const END_ENTITIES = 'endEntities';
export const END_ID = 'endId';
export const END_OBJECTS = 'endObjects';
export const END_OFFSET = 'endOffset';
export const ID = 'id';
export const INPUT = 'input';
export const INSTRUCTION = 'instruction';
export const IS_CHILD = 'isChild';
export const IS_KEY_FRAME = 'isKeyFrame';
export const IS_MODEL = 'isModel';
export const IS_VISIBLE = 'isVisible';
export const JOBS = 'jobs';
export const JOB_NAME = 'jobName';
export const MANDATORY = 'mandatory';
export const METADATA = 'jsonMetadata';
export const MID = 'mid';
export const ML_TASK = 'mlTask';
export const MODELS = 'models';
export const NAME = 'name';
export const POINTS = 'points';
export const CODE = 'code';
export const REQUIRED = 'required';
export const SEARCHBAR_IDENTIFIER = 'cypress-searchBar';
export const SHORTCUT = 'shortcut';
export const START_ENTITIES = 'startEntities';
export const START_OBJECTS = 'startObjects';
export const TARGET_JOB = 'targetJob';
export const TOOLS = 'tools';
export const TYPE = 'type';
export const LABEL_VERSION = 'labelVersion';

export const BOUNDING_POLY = 'boundingPoly';
export const NORMALIZED_VERTICES = 'normalizedVertices';
export const POLYS = 'polys';

export const LAST_EDITING_TIME = 'lastEditingTime';

// Since jobs interface form is recursive (jobs within jobs, etc.), we need to keep track of what level of nesting corresponds to specific type of the job

// This level corresponds to jobs at the top of the list, meaning they'r not children of other jobs.
export const ROOT_JOB_LEVEL = 1;
// This level indicates how much nesting we can have when it comes to creating subjobs of (sub)jobs.
export const LEAF_SUBJOB_LEVEL = 4;

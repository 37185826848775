import type { AssetLabelState } from './types';

import { type Asset } from '../../__generated__/globalTypes';

export const initialStateAssetLabel = {};

export const initialStateVariables = {
  nonSubmittedViewedAssetIds: [] as string[],
  viewedAssetIds: [] as string[],
  ...initialStateAssetLabel,
};

export const initialState: AssetLabelState = {
  asset: {} as Partial<Asset>,
  labeledAssets: [],
  reviewedAssets: [],
  ...initialStateVariables,
};

import L from 'leaflet';

/* eslint-disable no-underscore-dangle */

const END = {
  MSPointerDown: 'touchend',
  mousedown: 'mouseup',
  pointerdown: 'touchend',
  touchstart: 'touchend',
};

const MOVE = {
  MSPointerDown: 'touchmove',
  mousedown: 'mousemove',
  pointerdown: 'touchmove',
  touchstart: 'touchmove',
};

function distance(a: { x: number; y: number }, b: { x: number; y: number }) {
  const dx = a.x - b.x;
  const dy = a.y - b.y;
  return Math.sqrt(dx * dx + dy * dy);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
L.Handler.PathDrag = (L.Handler.PathHandler as any).extend({
  _onDrag(this: L.Handler.PathDrag, evt: Event) {
    if (!this._dragStartPoint || !this._startPoint || !this._matrix || !this._dragStartPoint) {
      throw new Error('Call _onDragStart before _onDrag');
    }

    if (!this._path.getMap()) {
      throw new Error('No map is defined for the path');
    }

    L.DomEvent.stop(evt);

    const eventToUse = (
      'touches' in evt && Array.isArray(evt.touches) ? evt.touches[0] : evt
    ) as MouseEvent;

    const layerPoint = this._path.getMap().mouseEventToLayerPoint(eventToUse);

    // skip taps
    if (evt.type === 'touchmove' && !this._path._dragMoved) {
      const totalMouseDragDistance = this._dragStartPoint.distanceTo(layerPoint);
      const map = this._path.getMap();
      if (map.options.tapTolerance && totalMouseDragDistance <= map.options.tapTolerance) {
        return;
      }
    }

    const { x } = layerPoint;
    const { y } = layerPoint;

    const dx = x - this._startPoint.x;
    const dy = y - this._startPoint.y;

    // Send events only if point was moved
    if (dx || dy) {
      if (!this._path._dragMoved) {
        this._path._dragMoved = true;
        this._path.fire('dragstart', evt);
        // we don't want that to happen on click
        this._path.bringToFront();
      }

      this._matrix[4] += dx;
      this._matrix[5] += dy;

      this._startPoint.x = x;
      this._startPoint.y = y;

      this._path.fire('predrag', evt);
      this._path._transform(this._matrix);
      this._path.fire('drag', evt);
    }
  },

  _onDragEnd(this: L.Handler.PathDrag, evt: Event) {
    if (!this._dragStartPoint || !this._startPoint || !this._matrix || !this._dragStartPoint) {
      throw new Error('_onDragEnd should be called only after _onDragStart and _onDrag');
    }

    if (!this._path.getMap()) {
      return;
    }

    const eventToUse = (
      'touches' in evt && Array.isArray(evt.touches) ? evt.touches[0] : evt
    ) as MouseEvent;

    const layerPoint = this._path.getMap().mouseEventToLayerPoint(eventToUse);
    const moved = this.moved();

    // apply matrix
    if (moved) {
      this._transformPoints(this._matrix);
      this._path._updatePath();

      // projects object further away
      this._path._project();
      // apply the transformation to the svg
      this._path._transform(null);
      L.DomEvent.stop(evt);
    }

    L.DomEvent.off(document.body, 'mousemove touchmove', this._onDrag, this);
    L.DomEvent.off(document.body, 'mouseup touchend', this._onDragEnd, this);

    // consistency
    if (moved) {
      this._path.fire('dragend', {
        distance: distance(this._dragStartPoint, layerPoint),
      });
    }

    if (this._mapDraggingWasEnabled) {
      this._path.getMap().dragging.enable();
    }

    this._matrix = null;
    this._startPoint = null;
    this._dragStartPoint = null;
    this._path._dragMoved = false;
  },

  _onDragStart(this: L.Handler.PathDrag, evt: L.ExtendedLeafletMouseEvent) {
    if (!this._path.getMap()) {
      return;
    }
    const eventType = evt.originalEvent._simulated ? 'touchstart' : 'mousedown';

    this._mapDraggingWasEnabled = false;
    this._startPoint = evt.layerPoint.clone();
    this._dragStartPoint = evt.layerPoint.clone();
    this._matrix = [1, 0, 0, 1, 0, 0];
    L.DomEvent.stop(evt.originalEvent);

    L.DomUtil.addClass(this._path._renderer._container, 'leaflet-interactive');
    L.DomEvent.on(document.body, MOVE[eventType], this._onDrag, this).on(
      document.body,
      END[eventType],
      this._onDragEnd,
      this,
    );

    if (this._path.getMap().dragging.enabled()) {
      // I guess it's required because mousdown gets simulated with a delay
      // this._path.getMap().dragging._draggable._onUp(evt);

      this._path.getMap().dragging.disable();
      this._mapDraggingWasEnabled = true;
    }
    this._path._dragMoved = false;
  },

  _transformPoints(this: L.Handler.PathDrag, matrixArray: L.MatrixArray) {
    if (!this._path.getMap()) {
      return;
    }
    const path = this._path;
    let i;
    let len;
    let latlng;

    const px = L.point(matrixArray[4], matrixArray[5]);

    const { crs } = path.getMap().options;

    if (!crs) {
      throw new Error('PathDrag: _transformPoints requires L.Map with valid CRS');
    }

    const { transformation } = crs;
    const scale = crs.scale(path.getMap().getZoom());
    const { projection } = crs;

    const diff = transformation
      .untransform(px, scale)
      .subtract(transformation.untransform(L.point(0, 0), scale));

    if (path._point && path._latlng) {
      path._latlng = projection.unproject(projection.project(path._latlng)._add(diff));
      path._point._add(px);
    } else if (path._latlngs) {
      const rings = path._rings || path._parts;
      if (rings) {
        const latlngs = (
          L.Util.isArray(path._latlngs[0]) ? path._latlngs : [path._latlngs]
        ) as L.LatLng[][];
        path._bounds = new L.LatLngBounds(latlngs[0][0], latlngs[0][0]);
        for (i = 0, len = rings.length; i < len; i += 1) {
          for (let j = 0, jj = rings[i].length; j < jj; j += 1) {
            latlng = latlngs[i][j];
            latlngs[i][j] = projection.unproject(projection.project(latlng)._add(diff));
            path._bounds.extend(latlngs[i][j]);
            rings[i][j]._add(px);
          }
        }
      }
    }
  },

  addHooks(this: L.Handler.PathDrag) {
    this._path.on('mousedown', this._onDragStart, this);

    this._path.options.className = this._path.options.className
      ? `${this._path.options.className} ${L.Handler.PathDrag.DRAGGING_CLS}`
      : L.Handler.PathDrag.DRAGGING_CLS;

    if (this._path._path) {
      L.DomUtil.addClass(this._path._path, L.Handler.PathDrag.DRAGGING_CLS);
    }
  },

  moved(this: L.Handler.PathDrag) {
    return this._path._dragMoved;
  },

  removeHooks(this: L.Handler.PathDrag) {
    this._path.off('mousedown', this._onDragStart, this);

    this._path.options.className = this._path?.options?.className?.replace(
      new RegExp(`\\s+${L.Handler.PathDrag.DRAGGING_CLS}`),
      '',
    );
    if (this._path._path) {
      L.DomUtil.removeClass(this._path._path, L.Handler.PathDrag.DRAGGING_CLS);
    }
  },

  statics: {
    DRAGGING_CLS: 'leaflet-path-draggable',
    makeDraggable(layer: L.Path) {
      // eslint-disable-next-line no-param-reassign
      layer.dragging = new L.Handler.PathDrag(layer);
      return layer;
    },
  },
});

import _get from 'lodash/get';

export enum RootRoute {
  ACCOUNT_ROUTE = 'ROOT_ACCOUNT_ROUTE',
  BEGIN_PROJECT_CREATION_ROUTE = 'ROOT_BEGIN_PROJECT_CREATION_ROUTE',
  DATASET_ROUTE = 'ROOT_DATASET_ROUTE',
  DEMO_PROJECT_ROUTE = 'ROOT_DEMO_PROJECT_ROUTE',
  DOCS_ROUTE = 'ROOT_DOCS_ROUTE',
  EXPLORE_ROUTE = 'ROOT_EXPLORE_ROUTE',
  FORGOT_PASSWORD_ROUTE = 'ROOT_FORGOT_PASSWORD_ROUTE',
  INTEGRATION_CREATION = 'ROOT_INTEGRATION_CREATION',
  INTEGRATION_CREATION_TYPE = 'ROOT_INTEGRATION_CREATION_TYPE',
  INTEGRATION_DETAIL = 'ROOT_INTEGRATION_DETAIL',
  LABEL_INSTR_FOLLOWING_ROUTE = 'ROOT_LABEL_INSTR_FOLLOWING_ROUTE',
  LABEL_ROUTE = 'ROOT_LABEL_ROUTE',
  LOGIN_SUCCESS = 'ROOT_LOGIN_SUCCESS',
  NEW_PROJECT_STORAGE_CONNECTION = 'NEW_PROJECT_STORAGE_CONNECTION',
  ORGANIZATIONS_ROUTE = 'ROOT_ORGANIZATIONS_ROUTE',
  ORGANIZATION_LIST_ROUTE = 'ROOT_ORGANIZATION_LIST_ROUTE',
  ORGANIZATION_MEMBERS_ROUTE = 'ORGANIZATION_MEMBERS_ROUTE',
  ORGANIZATION_ROUTE = 'ROOT_ORGANIZATION_ROUTE',
  PROJECT_DATA_TAB_ROUTE = 'ROOT_PROJECT_DATA_TAB_ROUTE',
  PROJECT_INTERFACE_TAB_ROUTE = 'ROOT_PROJECT_INTERFACE_TAB_ROUTE',
  PROJECT_LISTS = 'ROOT_PROJECT_LISTS',
  PROJECT_ROUTE = 'ROOT_PROJECT_ROUTE',
  PROJECT_STORAGE_CONNECTION = 'PROJECT_STORAGE_CONNECTION',
  REGISTER_ROUTE = 'ROOT_REGISTER_ROUTE',
  REVIEW_INSTR_FOLLOWING_ROUTE = 'ROOT_REVIEW_INSTR_FOLLOWING_ROUTE',
  REVIEW_ROUTE = 'ROOT_REVIEW_ROUTE',
  SIGNIN_ROUTE = 'ROOT_SIGNIN_ROUTE',
  SIGNOUT_ROUTE = 'ROOT_SIGNOUT_ROUTE',
  SIGNUP_ROUTE = 'ROOT_SIGNUP_ROUTE',
  USER_DETAIL_ROUTE = 'ROOT_USER_DETAIL_ROUTE',
}

export interface MatchParams {
  assetID: string;
  connectionId?: string;
  datasetID: string;
  id: string;
}

const ASSET_ID = ':assetID';
const PROJECT_ID = ':id';
const USER_ID = ':userID';
const CONNECTION_ID = ':connectionID';
const INTEGRATION_ID = ':integrationID';
const INTEGRATION_TYPE = ':integrationType';
const PROJECT_LIST_TYPE = ':listName';
const SELECTED_TAB = ':selectedTab';

export const baseURL = _get(window, '_env_.PROJECT_NAME__LABEL_FRONTEND');

export const myAccountEndpoint = 'my-account';

export const rootRoutes: Record<
  Exclude<RootRoute, RootRoute.DOCS_ROUTE | RootRoute.DATASET_ROUTE>,
  {
    exact: boolean;
    path: string;
  }
> = {
  [RootRoute.EXPLORE_ROUTE]: {
    exact: true,
    path: `/${baseURL}/projects/${PROJECT_ID}/explore`,
  },
  [RootRoute.LABEL_ROUTE]: {
    exact: true,
    path: `/${baseURL}/projects/label/${ASSET_ID}`,
  },
  [RootRoute.LABEL_INSTR_FOLLOWING_ROUTE]: {
    exact: true,
    path: `/${baseURL}/projects/label/instruction-following-evaluation/${ASSET_ID}`,
  },
  [RootRoute.REVIEW_ROUTE]: {
    exact: true,
    path: `/${baseURL}/projects/review/${ASSET_ID}`,
  },
  [RootRoute.REVIEW_INSTR_FOLLOWING_ROUTE]: {
    exact: true,
    path: `/${baseURL}/projects/review/instruction-following-evaluation/${ASSET_ID}`,
  },
  [RootRoute.SIGNIN_ROUTE]: {
    exact: true,
    path: `/${baseURL}/signin`,
  },
  [RootRoute.SIGNUP_ROUTE]: {
    exact: true,
    path: `/${baseURL}/signup`,
  },
  [RootRoute.SIGNOUT_ROUTE]: {
    exact: true,
    path: `/${baseURL}/signout`,
  },
  [RootRoute.FORGOT_PASSWORD_ROUTE]: {
    exact: true,
    path: `/${baseURL}/forgot-password`,
  },
  [RootRoute.BEGIN_PROJECT_CREATION_ROUTE]: {
    exact: true,
    path: `/${baseURL}/projects/create-project`,
  },
  [RootRoute.DEMO_PROJECT_ROUTE]: {
    exact: true,
    path: `/${baseURL}/projects/demo-project`,
  },
  [RootRoute.ACCOUNT_ROUTE]: {
    exact: false,
    path: `/${baseURL}/${myAccountEndpoint}`,
  },
  [RootRoute.ORGANIZATION_LIST_ROUTE]: {
    exact: true,
    path: `/${baseURL}/organization-list`,
  },
  [RootRoute.ORGANIZATION_ROUTE]: {
    exact: false,
    path: `/${baseURL}/organization`,
  },
  [RootRoute.ORGANIZATION_MEMBERS_ROUTE]: {
    exact: true,
    path: `/${baseURL}/organization/members`,
  },
  [RootRoute.INTEGRATION_CREATION]: {
    exact: true,
    path: `/${baseURL}/integration/create`,
  },
  [RootRoute.INTEGRATION_CREATION_TYPE]: {
    exact: true,
    path: `/${baseURL}/integration/create/${INTEGRATION_TYPE}`,
  },
  [RootRoute.INTEGRATION_DETAIL]: {
    exact: true,
    path: `/${baseURL}/integration/${INTEGRATION_ID}/${SELECTED_TAB}`,
  },
  [RootRoute.PROJECT_ROUTE]: {
    exact: false,
    path: `/${baseURL}/projects/${PROJECT_ID}`,
  },
  [RootRoute.LOGIN_SUCCESS]: {
    exact: true,
    path: `/${baseURL}/login/success`,
  },
  [RootRoute.NEW_PROJECT_STORAGE_CONNECTION]: {
    exact: true,
    path: `/${baseURL}/project/${PROJECT_ID}/new-connection`,
  },
  [RootRoute.PROJECT_STORAGE_CONNECTION]: {
    exact: true,
    path: `/${baseURL}/project/${PROJECT_ID}/connections/${CONNECTION_ID}`,
  },
  [RootRoute.USER_DETAIL_ROUTE]: {
    exact: true,
    path: `/${baseURL}/user/${USER_ID}`,
  },
  [RootRoute.REGISTER_ROUTE]: {
    exact: true,
    path: `/${baseURL}/register`,
  },
  [RootRoute.ORGANIZATIONS_ROUTE]: {
    exact: true,
    path: `/${baseURL}/organizations`,
  },
  [RootRoute.PROJECT_DATA_TAB_ROUTE]: {
    exact: true,
    path: `/${baseURL}/projects/${PROJECT_ID}/menu/settings/data`,
  },
  [RootRoute.PROJECT_INTERFACE_TAB_ROUTE]: {
    exact: true,
    path: `/${baseURL}/projects/${PROJECT_ID}/menu/settings/interface`,
  },
  [RootRoute.PROJECT_LISTS]: {
    exact: true,
    path: `/${baseURL}/lists/${PROJECT_LIST_TYPE}`,
  },
};

import {
  type AnnotationCategory,
  type EntityRelation,
  type ImageBoundingPoly,
  type ImageModel,
  Input,
  type Job,
  type Jobs,
  type JsonCategory as Category,
  type JsonResponse,
  MachineLearningTask,
  ModelName,
  type ObjectAnnotation2D,
  type ObjectRelation,
  type PoseEstimationPoint,
} from '@kili-technology/types';
import { type Action, type ThunkDispatch } from '@reduxjs/toolkit';
import { get as _get, uniqBy as _uniqBy } from 'lodash';
import { batch } from 'react-redux';
import { ActionCreators } from 'redux-undo';

import { InputType, type LabelType } from '@/__generated__/globalTypes';
import { ANY_RELATION_VALUE } from '@/components/InterfaceBuilder/FormInterfaceBuilder/JobCategory/JobCategoryRelation';
import {
  IS_KEY_FRAME,
  RelationObjectType,
} from '@/components/InterfaceBuilder/FormInterfaceBuilder/constants';
import { type AnnotationData } from '@/components/asset-ui/Common/Timeline/Timeline';
import { getFrameArrayFromFrameOnwards } from '@/components/asset-ui/Frame/helpers';
import { changeAnnotationsClassVideoSplitWithHistory } from '@/graphql/annotations/helpers/changeClass';
import { isClassificationCategoryChecked } from '@/pages/projects/label/LabelDialog/LabelInterface/JobsColumn/components/JobCategory/selectors';
import { doesProjectUseSplit } from '@/redux/project/helpers/doesProjectUseSplit';
import { rotateAnnotationByAngle } from '@/services/assets/rotation';
import { type ObjectPositionInfo } from '@/services/assets/video';
import { getCategoryCodeFromAnnotation } from '@/services/jobs/categories';
import { createObjectInFrameResponse } from '@/services/jobs/create';
import {
  deleteObjectInSelectedFramesFromFrameResponses,
  removeObjectsInFramesFromFrameResponses,
  unConvertFirstFrameToKeyFrame,
} from '@/services/jobs/delete';
import { getAllowedObjects } from '@/services/jobs/objectTasks';
import { getResponsesToSet, type KiliAnnotation } from '@/services/jobs/setResponse';
import {
  computeChangesToMakeForUpdate,
  getJobSubJobsKeyFramesInFramesArray,
  updateObjectInFrameResponse,
} from '@/services/jobs/update';
import { getNewAccordionAfterCategoryClick } from '@/services/jsonInterface/accordion';
import { END_ENTITIES_STEP, START_ENTITIES_STEP } from '@/services/text/constants';
import {
  getMidsToHideWhenSelectingRelation,
  notificationMessageAllowed,
  notificationMessageNoDuplicates,
} from '@/services/text/helpers';
import { type AppThunk, store } from '@/store';
import {
  labelInterfaceUpdateField,
  removeAllSelectedObjectIds,
  replaceAllWithSelectedObjectId,
  useStore,
} from '@/zustand';
import { type SelectedFrames } from '@/zustand/label-frame';
import { selectToolOptions } from '@/zustand/label-interface/selectors';
import { useHistoryStore } from '@/zustand-history';

import {
  getAnnotationFromMid,
  getAnnotationFromMidAndAnnotations,
  getKeyAnnotations,
  getMlTaskFromJobName,
  type KeyAnnotation,
} from './helpers';
import { deleteAnnotationInFrameResponses } from './helpers/deleteAnnotationInFrameResponses';
import { reCreateAnnotationInFrameResponses } from './helpers/reCreateAnnotationInFrameResponses';
import { updateSubJob } from './helpers/updateSubJob';
import {
  frameResponsesObjectsInfo,
  jobsAnnotations,
  jobsCurrentMlTask,
  jobsObjectChildrenResponse,
  jobsResponses,
  jobsRotation,
} from './selectors';
import {
  JOBS_ADD_ANNOTATION,
  JOBS_INITIALIZE,
  JOBS_REMOVE_ANNOTATIONS,
  JOBS_SET_CLASSIFICATION_RESPONSE,
  JOBS_SET_CURRENT_FRAME_RESPONSE,
  JOBS_SET_RESPONSE,
  JOBS_SET_RESPONSE_AT_PAGE_LEVEL,
  JOBS_SET_RESPONSES,
  JOBS_UPDATE_ANNOTATIONS,
} from './slice';
import {
  type ChangeClassPayload,
  type EndRelationPayload,
  type JobsAddAnnotationPayload,
  type JobsRemoveAnnotationsPayload,
  type JobsSetResponseClassificationAtPageLevelPayload,
  type JobsSetResponseClassificationCleanPayload,
  type JobsSetResponseDefaultPayload,
  type JobsSetResponsesPayload,
  type JobsSetResponseSpeechToTextPayload,
  type JobsSetResponseTranscriptionAtPageLevelPayload,
  type JobsSetResponseTranscriptionPayload,
  type JobsUpdateAnnotationsPayload,
  type RelationAnnotations,
  type StartRelationPayload,
} from './types';

import { availableClassesToChange } from '../../services/jobs/changeClass';
import selectCurrentFrame from '../../zustand/label-frame/selectors';
import { addNotification } from '../application/actions';
import {
  initializeState as initializeJobState,
  setCurrentJobName,
  setSelectedCategory,
} from '../job/actions';
import { jobCurrentCategories, jobCurrentJobName } from '../job/selectors';
import { changeAnnotationClassVideo } from '../label-frame/actions/changeAnnotationClassVideo';
import { updateFrameResponses } from '../label-frame/actions/updateFrameResponses';
import { labelFramesFrameResponses } from '../label-frames/selectors';
import { type ChildVideoJob, type FrameResponses } from '../label-frames/types';
import { type FlatNode, type FlatTree } from '../project/ontologyTree';
import { projectTreeFlat } from '../project/selectors';
import { projectInputType, projectJobs, projectJsonInterface } from '../selectors';
import { type State } from '../types';

export type ActionDefaultReturn = { payload: JobsSetResponseDefaultPayload; type: string };
type ActionResponsesReturn = { payload: JobsSetResponsesPayload; type: string };

export const initializeState = (): {
  payload: undefined;
  type: string;
} => {
  return JOBS_INITIALIZE();
};

export const toggleAccordionAfterCategoryClick = (
  job: [string, Job],
  category: [string, Category],
  path: string[],
  parentMid?: string,
): AppThunk => {
  return async (dispatch, getState) => {
    const state = getState();
    const { accordionTree } = useStore.getState().labelInterface;
    const input = job[1]?.content?.input;
    const shouldCloseClickedCategory =
      input === Input.CHECKBOX &&
      !isClassificationCategoryChecked(state, { category, job, parentMid, path, recursion: 0 });
    const shouldCloseObjects = job[1]?.mlTask !== MachineLearningTask.CLASSIFICATION;
    const newAccordion = getNewAccordionAfterCategoryClick(
      job,
      category,
      accordionTree,
      path,
      shouldCloseClickedCategory,
      shouldCloseObjects,
    );
    labelInterfaceUpdateField({ path: 'accordionTree', value: newAccordion });
  };
};

export const initializeHistory = (): AppThunk => {
  return async dispatch => {
    dispatch(ActionCreators.clearHistory());
  };
};

export const setResponses = (
  jobs: Jobs,
  labelType: LabelType,
  responses: JsonResponse,
): ActionResponsesReturn => {
  const responsesToSet = getResponsesToSet(jobs, labelType, responses);
  return JOBS_SET_RESPONSES({ responsesToSet, shouldUseInitialState: true });
};

export const setCurrentFrameResponse = (
  jobs: Jobs,
  labelType: LabelType,
  responses: JsonResponse,
): ActionResponsesReturn => {
  const responsesToSet = getResponsesToSet(jobs, labelType, responses);
  return JOBS_SET_CURRENT_FRAME_RESPONSE({ responsesToSet });
};

export const addAnnotation = (
  payload: JobsAddAnnotationPayload,
): { payload: JobsAddAnnotationPayload; type: string } => {
  return JOBS_ADD_ANNOTATION(payload);
};

export const removeAnnotations = (
  mids: string[],
): { payload: JobsRemoveAnnotationsPayload; type: string } => {
  const state = store.getState();
  const annotations = jobsAnnotations(state) as KiliAnnotation[];
  const jobs = projectJobs(state);

  const annotationsToRemove = mids
    .map(mid => getAnnotationFromMidAndAnnotations(mid, annotations))
    .filter((annotation): annotation is KiliAnnotation => !!annotation)
    .map(annotation => {
      const { jobName, mid } = annotation;
      const mlTask = annotation.mlTask || getMlTaskFromJobName(jobs, jobName);

      if (mlTask === undefined) {
        throw new Error(`Unable to find mlTask of annotation to delete with mid ${mid}`);
      }

      const objectParts: string[] = Object.hasOwn(annotation, 'allPoints')
        ? (annotation as KiliAnnotation & { allPoints: PoseEstimationPoint[] }).allPoints.map(
            point => point.code,
          )
        : [];

      return {
        jobName,
        mid,
        mlTask,
        objectParts,
      };
    });

  return JOBS_REMOVE_ANNOTATIONS({ annotationsToRemove });
};

export const setNamedEntitiesRecognitionResponse = (
  payload: JobsSetResponseDefaultPayload,
): ActionDefaultReturn => {
  return JOBS_SET_RESPONSE({
    jobName: payload.jobName,
    jobResponse: payload.jobResponse,
    mlTask: MachineLearningTask.NAMED_ENTITIES_RECOGNITION,
    parentMid: payload.parentMid,
  });
};

export const setObjectDetectionResponse = (
  payload: JobsSetResponseDefaultPayload,
): ActionDefaultReturn => {
  return JOBS_SET_RESPONSE({
    jobName: payload.jobName,
    jobResponse: payload.jobResponse,
    mlTask: MachineLearningTask.OBJECT_DETECTION,
    parentMid: payload.parentMid,
  });
};

export const setPoseEstimationResponse = (
  payload: JobsSetResponseDefaultPayload,
): { payload: JobsSetResponsesPayload; type: string } => {
  const responsesToSet = [
    {
      jobName: payload.jobName,
      jobResponse: payload.jobResponse,
      mlTask: MachineLearningTask.POSE_ESTIMATION,
      parentMid: payload.parentMid,
    },
    {
      jobName: payload.jobName,
      jobResponse: payload.jobResponse,
      mlTask: MachineLearningTask.OBJECT_DETECTION,
      parentMid: payload.parentMid,
    },
  ];
  return JOBS_SET_RESPONSES({ responsesToSet, shouldUseInitialState: false });
};

export const setSpeechToTextResponse = (
  payload: JobsSetResponseSpeechToTextPayload,
): ActionDefaultReturn => {
  return JOBS_SET_RESPONSE({
    jobName: payload.jobName,
    jobResponse: payload.jobResponse,
    mlTask: MachineLearningTask.SPEECH_TO_TEXT,
    parentMid: payload.parentMid,
  });
};

export const setTranscriptionResponse = (
  payload: JobsSetResponseDefaultPayload,
): ActionDefaultReturn => {
  return JOBS_SET_RESPONSE({
    jobName: payload.jobName,
    jobResponse: payload.jobResponse,
    mlTask: MachineLearningTask.TRANSCRIPTION,
    parentMid: payload.parentMid,
  });
};

export const setAutoTranscriptionResponseFromAnnotation = (payload: {
  annotation: { boundingPoly: ImageBoundingPoly };
  jobNames: string[];
  parentMid: string;
  text: string;
}): AppThunk => {
  return async (dispatch, getState) => {
    const { annotation, jobNames, text, parentMid } = payload;
    const state = getState();
    const rotation = jobsRotation(state);
    const currentObjectResponse = jobsObjectChildrenResponse(state, parentMid);
    const { boundingPoly } = annotation;
    const rotatedAnnotation = rotateAnnotationByAngle(rotation, {
      boundingPoly: [boundingPoly],
    } as ObjectAnnotation2D) as ObjectAnnotation2D;
    if (!rotatedAnnotation.boundingPoly) {
      return;
    }

    jobNames.forEach(jobName => {
      const currentText = currentObjectResponse?.TRANSCRIPTION?.[jobName];
      const shouldChangeTranscription = !currentText;
      if (shouldChangeTranscription) {
        dispatch(
          setTranscriptionResponse({
            jobName,
            jobResponse: { text },
            parentMid,
          }),
        );
      }
    });
  };
};

export const updateClassificationResponseAndClean = (
  payload: JobsSetResponseClassificationCleanPayload,
): ActionDefaultReturn => {
  return JOBS_SET_CLASSIFICATION_RESPONSE({
    jobName: payload.jobName,
    jobResponse: {
      categories: payload.categoryCodes.map(categoryCode => ({
        confidence: 100,
        name: categoryCode,
      })),
    },
    jobsToClean: payload.jobsToClean,
    parentMid: payload.parentMid,
  });
};

export const updateBasicClassificationAtPageLevelResponse = (
  payload: JobsSetResponseClassificationAtPageLevelPayload,
): ActionDefaultReturn => {
  return JOBS_SET_RESPONSE_AT_PAGE_LEVEL({
    jobName: payload.jobName,
    jobResponse: {
      categories: payload.categoryCodes.map(categoryCode => ({
        confidence: 100,
        name: categoryCode,
      })),
      page: payload.pageNumber,
    },
    mlTask: MachineLearningTask.PAGE_LEVEL_CLASSIFICATION,
    pageNumber: payload.pageNumber,
  });
};

export const updateResponseForAnnotations = (
  payload: JobsUpdateAnnotationsPayload,
): { payload: JobsUpdateAnnotationsPayload; type: string } => {
  return JOBS_UPDATE_ANNOTATIONS({
    annotations: payload.annotations,
    mlTask: payload.mlTask,
    noHistory: payload?.noHistory,
  });
};

export const updateBasicTranscriptionResponse = (
  payload: JobsSetResponseTranscriptionPayload,
): ActionDefaultReturn => {
  return JOBS_SET_RESPONSE({
    jobName: payload.jobName,
    jobResponse: {
      text: payload.text,
    },
    mlTask: MachineLearningTask.TRANSCRIPTION,
    parentMid: payload.parentMid,
  });
};

export const updateBasicTranscriptionAtPageLevelResponse = (
  payload: JobsSetResponseTranscriptionAtPageLevelPayload,
): ActionDefaultReturn => {
  return JOBS_SET_RESPONSE_AT_PAGE_LEVEL({
    jobName: payload.jobName,
    jobResponse: {
      page: payload.pageNumber,
      text: payload.text,
    },
    mlTask: MachineLearningTask.PAGE_LEVEL_TRANSCRIPTION,
    pageNumber: payload.pageNumber,
  });
};

export const updateTranscriptionAtPageLevelResponse = ({
  jobName,
  pageNumber,
  text,
}: {
  jobName: string;
  pageNumber: number;
  text: string;
}): AppThunk => {
  return async dispatch => {
    dispatch(updateBasicTranscriptionAtPageLevelResponse({ jobName, pageNumber, text }));
  };
};

export const updateTranscriptionResponse = ({
  jobName,
  parentMid,
  text,
}: {
  jobName: string;
  parentMid?: string;
  text: string;
}): AppThunk => {
  return async (dispatch, getState) => {
    const state: State = getState();
    const inputType = projectInputType(state);

    if (inputType === InputType.VIDEO) {
      const responses = store.getState().labelFrames.frameResponses;
      const currentFrame = selectCurrentFrame(inputType)(useStore.getState());
      const objectsInfo = frameResponsesObjectsInfo(state);
      const lastObjectFrame = parentMid
        ? _get(objectsInfo, [parentMid, 'frames'], []).slice(-1)[0]
        : null;
      const { numberOfFrames: totalFrames } = useStore.getState().labelFrame.videoParams;
      const maxFrame = lastObjectFrame === null ? totalFrames : lastObjectFrame + 1;

      const previousSubJob = parentMid
        ? _get(responses, [currentFrame, parentMid, jobName], null)
        : _get(responses, [currentFrame, jobName], null);
      const subJob = {
        isKeyFrame: true,
        text,
      };

      const action = {
        name: 'updateTranscription',
        redo: () => {
          dispatch(
            updateSubJobInFrameResponse(currentFrame, jobName, subJob, maxFrame, null, parentMid),
          );
        },
        undo: () => {
          dispatch(
            updateSubJobInFrameResponse(
              currentFrame,
              jobName,
              previousSubJob,
              maxFrame,
              null,
              parentMid,
            ),
          );
        },
      };

      dispatch(
        updateSubJobInFrameResponse(currentFrame, jobName, subJob, maxFrame, null, parentMid),
      );
      useHistoryStore.getState().history.addAction(action);
    }
    dispatch(
      updateBasicTranscriptionResponse({
        jobName,
        parentMid,
        text,
      }),
    );
  };
};

const getJobsToClean = ({
  initialCategoryCodes,
  finalCategoryCodes,
  treeFlat,
  jobName,
}: {
  finalCategoryCodes: string[];
  initialCategoryCodes: string[];
  jobName: string;
  treeFlat: FlatTree;
}) => {
  const jobUnselectedCategories = initialCategoryCodes.filter(
    code => !finalCategoryCodes.includes(code),
  );
  const categoryNodesToClean = jobUnselectedCategories
    .map(code => treeFlat?.[jobName]?.[code])
    .flat();
  const jobsToClean = _uniqBy(
    categoryNodesToClean.filter(node => !!node && node?.jobName !== jobName),
    node => node.jobName,
  );

  return jobsToClean;
};

const getArrayOfUpdatesFramesForClassificationJob = (
  currentFrame: number,
  jobName: string,
  responses: FrameResponses,
  maxFrame: number,
  parentMid?: string,
) => {
  const { firstFrame, lastFrame } = computeChangesToMakeForUpdate(
    currentFrame,
    jobName,
    [],
    responses,
    maxFrame,
    parentMid,
  );

  return Array.from({ length: lastFrame + 1 - firstFrame }, (_, index) => index + firstFrame - 1);
};

export const updateClassificationResponse = ({
  categoryCodes,
  jobName,
  parentMid,
}: {
  categoryCodes: string[];
  jobName: string;
  parentMid: string | undefined;
}): AppThunk => {
  return async (dispatch, getState) => {
    const state = getState();
    const inputType = projectInputType(state);
    const treeFlat = projectTreeFlat(state);
    const stateJobs = projectJobs(state);

    const { isSelected: isSegmentationToolSelected } = selectToolOptions('Segmentation')(
      useStore.getState(),
    );

    let jobNameToUse = jobName;
    if (isSegmentationToolSelected) {
      const models = (stateJobs?.[jobName]?.models ?? {}) as ImageModel;
      jobNameToUse = models?.[ModelName.INTERACTIVE_SEGMENTATION]?.job ?? '';
    }

    const { mlTask, content } = stateJobs[jobNameToUse] ?? {};
    batch(() => {
      const isAudio = inputType === InputType.AUDIO;
      if (mlTask === MachineLearningTask.CLASSIFICATION || isAudio) {
        if (isAudio) {
          dispatch(setCurrentJobName(jobNameToUse));
        }
        const jobCategoryCodes = Object.keys(content?.categories ?? {});
        const jobsToClean = getJobsToClean({
          finalCategoryCodes: categoryCodes,
          initialCategoryCodes: jobCategoryCodes,
          jobName: jobNameToUse,
          treeFlat,
        });

        dispatch(
          updateClassificationResponseAndClean({
            categoryCodes,
            jobName: jobNameToUse,
            jobsToClean,
            parentMid,
          }),
        );
      }
    });
    if (inputType === InputType.VIDEO && mlTask === MachineLearningTask.CLASSIFICATION) {
      const responses = store.getState().labelFrames.frameResponses;
      const currentFrame = selectCurrentFrame(inputType)(useStore.getState());
      const objectsInfo = frameResponsesObjectsInfo(state);
      const lastObjectFrame = parentMid
        ? _get(objectsInfo, [parentMid, 'frames'], []).slice(-1)[0]
        : null;
      const { numberOfFrames: totalFrames } = useStore.getState().labelFrame.videoParams;
      const maxFrame = lastObjectFrame === null ? totalFrames : lastObjectFrame + 1;

      const categories = categoryCodes.map((categoryCode: string) => ({
        confidence: 100,
        name: categoryCode,
      }));

      const previousSubJob = parentMid
        ? _get(responses, [currentFrame, parentMid, jobName], null)
        : _get(responses, [currentFrame, jobName], null);
      const subJob = {
        categories,
        isKeyFrame: true,
      };

      const jobCategoryCodes = Object.keys(content?.categories ?? {});
      const childrenJobsToClean = getJobsToClean({
        finalCategoryCodes: categoryCodes,
        initialCategoryCodes: jobCategoryCodes,
        jobName: jobNameToUse,
        treeFlat,
      });

      const framesArray = getArrayOfUpdatesFramesForClassificationJob(
        currentFrame,
        jobName,
        responses,
        maxFrame,
        parentMid,
      );

      const childrenJobsToReCreate = getJobSubJobsKeyFramesInFramesArray(
        framesArray,
        responses,
        parentMid,
      );

      const action = {
        name: 'updateClassification',
        redo: () => {
          dispatch(
            updateSubJobInFrameResponse(
              currentFrame,
              jobName,
              subJob,
              maxFrame,
              childrenJobsToClean,
              parentMid,
            ),
          );
        },
        undo: () => {
          dispatch(
            reCreateSubJobsInFrameResponse(
              currentFrame,
              jobName,
              previousSubJob,
              maxFrame,
              childrenJobsToReCreate,
              parentMid,
            ),
          );
        },
      };

      dispatch(
        updateSubJobInFrameResponse(
          currentFrame,
          jobName,
          subJob,
          maxFrame,
          childrenJobsToClean,
          parentMid,
        ),
      );
      useHistoryStore.getState().history.addAction(action);
    }
  };
};

export const updateClassificationAtPageLevelResponse = ({
  categoryCodes,
  jobName,
  pageNumber,
}: {
  categoryCodes: string[];
  jobName: string;
  pageNumber: number;
}): AppThunk => {
  return async dispatch => {
    batch(() => {
      dispatch(
        updateBasicClassificationAtPageLevelResponse({
          categoryCodes,
          jobName,
          pageNumber,
        }),
      );
    });
  };
};

export const deleteVideoObject = (mid: string) => {
  const state: State = store.getState();
  const annotations = jobsAnnotations(state) as KiliAnnotation[];
  const annotation = getAnnotationFromMidAndAnnotations(mid, annotations);

  if (!annotation) {
    throw new Error(`Unable to find annotation to delete with mid ${mid}`);
  }

  const { jobName } = annotation;
  const mlTask = annotation.mlTask || getMlTaskFromJobName(projectJobs(state), jobName);

  if (mlTask === undefined) {
    throw new Error(`Unable to find mlTask of annotation to delete with mid ${mid}`);
  }

  const jobCategoryTree = projectTreeFlat(state);
  const frameJsonResponse = labelFramesFrameResponses(state);
  const keyAnnotations = getKeyAnnotations(jobName, frameJsonResponse, jobCategoryTree, mid);
  const objectInfo = frameResponsesObjectsInfo(state)[mid];

  const action = {
    name: 'deleteVideoObject',
    redo: () => {
      store.dispatch(deleteAnnotationInFrameResponses(mid, jobName));
    },
    undo: () => {
      store.dispatch(reCreateAnnotationInFrameResponses(keyAnnotations, objectInfo, mid));
    },
  };

  store.dispatch(deleteAnnotationInFrameResponses(mid, jobName));
  useHistoryStore.getState().history.addAction(action);
};

export const deleteObjectFromFrameOnwards = (mid: string, frameToDeleteFrom: number) => {
  const state: State = store.getState();
  const annotations = jobsAnnotations(state) as KiliAnnotation[];
  const jobCategoryTree = projectTreeFlat(state);
  const annotation = getAnnotationFromMidAndAnnotations(mid, annotations);

  if (!annotation) {
    throw new Error(`Unable to find annotation to delete with mid ${mid}`);
  }

  const { jobName } = annotation;

  if (jobName === undefined) {
    throw new Error(`Unable to find jobName of annotation to delete with mid ${mid}`);
  }

  const mlTask = annotation.mlTask || getMlTaskFromJobName(projectJobs(state), jobName);

  if (mlTask === undefined) {
    throw new Error(`Unable to find mlTask of annotation to delete with mid ${mid}`);
  }

  const frameJsonResponse = labelFramesFrameResponses(state);
  const objectInfo = frameResponsesObjectsInfo(state)[mid];
  const lastObjectFrame = objectInfo?.frames.slice(-1)[0];
  const { numberOfFrames: totalFrames } = useStore.getState().labelFrame.videoParams;
  const maxFrame = lastObjectFrame === undefined ? totalFrames : lastObjectFrame + 1;
  const keyAnnotations = getKeyAnnotations(jobName, frameJsonResponse, jobCategoryTree, mid);

  const filteredFramesArray = getFrameArrayFromFrameOnwards(
    [mid],
    frameToDeleteFrom,
    frameJsonResponse,
  );
  const framesArray = {
    max: Math.max(...filteredFramesArray),
    min: Math.min(...filteredFramesArray),
  };

  const action = {
    name: 'deleteFromThisFrame',
    redo: () => {
      store.dispatch(deleteSelectionInFrameResponses(framesArray, mid, jobName, maxFrame));
    },
    undo: () => {
      store.dispatch(reFillSelectionInFrameResponses(framesArray, mid, keyAnnotations, objectInfo));
    },
  };

  store.dispatch(deleteSelectionInFrameResponses(framesArray, mid, jobName, maxFrame));
  useHistoryStore.getState().history.addAction(action);
};

const deleteSelectionInFrameResponses = (
  selectedFrames: SelectedFrames,
  mid: string,
  jobName: string,
  maxFrame: number,
) => {
  const responses = store.getState().labelFrames.frameResponses;
  const newResponses = deleteObjectInSelectedFramesFromFrameResponses(
    selectedFrames as SelectedFrames,
    mid,
    jobName,
    responses,
    maxFrame,
  );

  return updateFrameResponses({ frameResponses: newResponses });
};

const reFillSelectionInFrameResponses = (
  selectedFrames: SelectedFrames,
  mid: string,
  keyAnnotations: KeyAnnotation[],
  objectInfo: ObjectPositionInfo,
) => {
  const responses = store.getState().labelFrames.frameResponses;
  const flatTree = projectTreeFlat(store.getState());

  let newResponses = { ...responses };

  const currentObjectInfo = frameResponsesObjectsInfo(store.getState())?.[mid];

  const keyAnnotationPriorToSelection = keyAnnotations
    .filter(keyAnnotation => keyAnnotation.keyFrame <= selectedFrames.min)
    .slice(-1)[0];
  const keyAnnotationLastOnSelection = keyAnnotations
    .filter(keyAnnotation => keyAnnotation.keyFrame <= selectedFrames.max)
    .slice(-1)[0];
  const filteredKeyAnnotations = keyAnnotations.filter(
    keyAnnotation =>
      keyAnnotation.keyFrame >=
        (keyAnnotationPriorToSelection
          ? keyAnnotationPriorToSelection.keyFrame
          : selectedFrames.min) &&
      keyAnnotation.keyFrame <=
        (keyAnnotationLastOnSelection ? keyAnnotationLastOnSelection.keyFrame : selectedFrames.max),
  );

  const endFrame = objectInfo.frames.slice(-1)[0];
  const selectedFramesArray = Array.from(
    { length: selectedFrames.max + 1 - selectedFrames.min },
    (_, index) => selectedFrames.min + index,
  );

  const jobKeyAnnotationPriorToSelection = keyAnnotations
    .filter(keyAnnotation => keyAnnotation.job !== undefined)
    .filter(keyAnnotation => keyAnnotation.keyFrame <= selectedFrames.min)
    .slice(-1)[0];

  if (filteredKeyAnnotations[0]?.job) {
    const newAnnotation = { ...filteredKeyAnnotations[0].job };
    if (IS_KEY_FRAME in newAnnotation) delete newAnnotation[IS_KEY_FRAME];

    newResponses = createObjectInFrameResponse(
      filteredKeyAnnotations[0].keyFrame,
      selectedFrames.max - filteredKeyAnnotations[0].keyFrame,
      newAnnotation as KiliAnnotation,
      newResponses,
    );
  } else {
    const newAnnotation = { ...jobKeyAnnotationPriorToSelection.job, isKeyFrame: false };

    newResponses = createObjectInFrameResponse(
      selectedFrames.min,
      selectedFrames.max - selectedFrames.min + 1,
      newAnnotation as KiliAnnotation,
      newResponses,
    );
  }

  if (
    currentObjectInfo &&
    currentObjectInfo.frames?.[0] !== objectInfo.frames?.[0] &&
    selectedFramesArray.includes(objectInfo.frames?.[0]) &&
    keyAnnotations.find(
      keyAnnotation => keyAnnotation.keyFrame === currentObjectInfo.frames?.[0],
    ) === undefined
  ) {
    newResponses = unConvertFirstFrameToKeyFrame(
      newResponses,
      currentObjectInfo.frames?.[0],
      objectInfo.jobName,
      mid,
    );
  }

  const firstJobKeyAnnotation = filteredKeyAnnotations[0]?.job
    ? filteredKeyAnnotations[0]
    : jobKeyAnnotationPriorToSelection;

  newResponses = updateObjectInFrameResponse(
    firstJobKeyAnnotation.keyFrame,
    firstJobKeyAnnotation.job as KiliAnnotation,
    newResponses,
  );

  if (filteredKeyAnnotations[0].subJobs) {
    filteredKeyAnnotations[0].subJobs.forEach(subJob => {
      newResponses = updateSubJob(
        filteredKeyAnnotations[0].keyFrame,
        subJob.subJobName,
        subJob.subJobValue,
        endFrame,
        newResponses,
        flatTree,
        null,
        mid,
      );
    });
  }

  const totalFramesArray = Array.from(
    { length: selectedFrames.max + 1 - firstJobKeyAnnotation.keyFrame },
    (_, index) => firstJobKeyAnnotation.keyFrame + index,
  );
  const removedFramesArray = totalFramesArray.filter(frame => !objectInfo.frames.includes(frame));
  newResponses = removeObjectsInFramesFromFrameResponses(
    removedFramesArray,
    mid,
    objectInfo.jobName,
    newResponses,
  );

  filteredKeyAnnotations.slice(1).forEach(keyAnnotation => {
    if (keyAnnotation.job) {
      newResponses = updateObjectInFrameResponse(
        keyAnnotation.keyFrame,
        keyAnnotation.job as KiliAnnotation,
        newResponses,
      );
    }
    if (keyAnnotation.subJobs) {
      keyAnnotation.subJobs.forEach(subJob => {
        newResponses = updateSubJob(
          keyAnnotation.keyFrame,
          subJob.subJobName,
          subJob.subJobValue,
          endFrame,
          newResponses,
          flatTree,
          null,
          mid,
        );
      });
    }
  });
  return updateFrameResponses({ frameResponses: newResponses });
};

export const deleteObjectInSelectedFrames = ({
  annotationData,
  selectedFrames,
}: {
  annotationData: AnnotationData;
  selectedFrames: { max: number; min: number };
}): AppThunk => {
  return async (dispatch, getState) => {
    const state: State = getState();
    const annotations = jobsAnnotations(state) as KiliAnnotation[];
    const inputType = projectInputType(state);
    const jobs = projectJobs(state);
    const jobCategoryTree = projectTreeFlat(state);

    let midToCut: string;
    if (annotationData.mid) midToCut = annotationData.mid;
    else {
      const { selectedObjectIds } = useStore.getState().labelInterface;
      if (selectedObjectIds.length !== 1)
        throw new Error(
          'Can only delete on annotation at a time with deleteObjectInSelectedFrames.',
        );
      [midToCut] = selectedObjectIds;
    }

    const annotation = getAnnotationFromMidAndAnnotations(midToCut, annotations);
    const jobNameOfMid = annotationData.jobName || annotation?.jobName;
    if (!jobNameOfMid) return;

    const mlTaskOfMid = annotationData.mlTask || getMlTaskFromJobName(jobs, jobNameOfMid);
    if (!mlTaskOfMid) return;

    if (inputType === InputType.VIDEO && mlTaskOfMid === MachineLearningTask.OBJECT_DETECTION) {
      const frameJsonResponse = labelFramesFrameResponses(state);
      if (!frameJsonResponse) return;

      const objectInfo = frameResponsesObjectsInfo(state)?.[midToCut];
      const lastObjectFrame = midToCut ? objectInfo?.frames.slice(-1)[0] : undefined;
      const { numberOfFrames: totalFrames } = useStore.getState().labelFrame.videoParams;
      const maxFrame = lastObjectFrame === undefined ? totalFrames : lastObjectFrame + 1;
      const keyAnnotations = getKeyAnnotations(
        jobNameOfMid,
        frameJsonResponse,
        jobCategoryTree,
        midToCut,
      );

      const action = {
        name: 'deleteSelection',
        redo: () => {
          dispatch(
            deleteSelectionInFrameResponses(selectedFrames, midToCut, jobNameOfMid, maxFrame),
          );
        },
        undo: () => {
          dispatch(
            reFillSelectionInFrameResponses(selectedFrames, midToCut, keyAnnotations, objectInfo),
          );
        },
      };

      dispatch(deleteSelectionInFrameResponses(selectedFrames, midToCut, jobNameOfMid, maxFrame));
      useHistoryStore.getState().history.addAction(action);
    }
  };
};

const updateSubJobInFrameResponse = (
  currentFrame: number,
  jobName: string,
  subJob: ChildVideoJob | null,
  maxFrame: number,
  classificationChildrenJobsToClean: FlatNode[] | null,
  parentMid?: string,
) => {
  const responses = store.getState().labelFrames.frameResponses;
  const flatTree = projectTreeFlat(store.getState());

  const newResponses = updateSubJob(
    currentFrame,
    jobName,
    subJob,
    maxFrame,
    responses,
    flatTree,
    classificationChildrenJobsToClean,
    parentMid,
  );
  return updateFrameResponses({ frameResponses: newResponses });
};

const reCreateSubJobsInFrameResponse = (
  currentFrame: number,
  jobName: string,
  subJob: ChildVideoJob | null,
  maxFrame: number,
  subJobsToReCreate: {
    frame: number;
    jobName: string;
    subJob: ChildVideoJob | null;
  }[],
  parentMid?: string,
) => {
  const responses = store.getState().labelFrames.frameResponses;
  const flatTree = projectTreeFlat(store.getState());

  let newResponses = updateSubJob(
    currentFrame,
    jobName,
    subJob,
    maxFrame,
    responses,
    flatTree,
    [],
    parentMid,
  );

  subJobsToReCreate.forEach(subJobToReCreate => {
    newResponses = updateSubJob(
      subJobToReCreate.frame,
      subJobToReCreate.jobName,
      subJobToReCreate.subJob,
      maxFrame,
      newResponses,
      flatTree,
      [],
      parentMid,
    );
  });

  return updateFrameResponses({ frameResponses: newResponses });
};

export const changeClassVideo = (payload: ChangeClassPayload): AppThunk => {
  return (dispatch, getState) => {
    const { jobName, categoryCode } = payload;
    const { labelFrame, labelInterface } = useStore.getState();
    const { selectedObjectIds } = labelInterface;
    const { incrementJobCategoryAnnotationCount, setJobCategoriesToMids } = labelFrame;
    const state = getState();
    const jobsInterface = projectJobs(store.getState());
    const job = jobsInterface[jobName];
    const category = jobsInterface[jobName].content.categories?.[categoryCode];
    const name = category?.name;
    const path = [jobName, 'categories', categoryCode];

    if (category === undefined || name === undefined) {
      throw new Error('Invalid category');
    }

    batch(() => {
      dispatch(initializeJobState());
      dispatch(toggleAccordionAfterCategoryClick([jobName, job], [categoryCode, category], path));

      if (doesProjectUseSplit()) {
        const { ANNOTATION_JOB_COUNTER } = useStore.getState().labelFrame.jobCategoriesToMids;
        const categoryCurrentCounter = ANNOTATION_JOB_COUNTER[jobName]?.[categoryCode] ?? 0;

        incrementJobCategoryAnnotationCount({
          category: categoryCode,
          increment: selectedObjectIds.length,
          jobName,
        });
        changeAnnotationsClassVideoSplitWithHistory({
          categoryCode,
          categoryCurrentCounter,
          jobName,
          mids: selectedObjectIds,
        });
      } else {
        const annotations = jobsAnnotations(state);

        selectedObjectIds.forEach(objectId => {
          const annotation = getAnnotationFromMidAndAnnotations(objectId, annotations);

          if (!annotation) {
            throw new Error('Annotation for changeClass not found');
          }
          if (annotation.categories[0].name === categoryCode) {
            return;
          }

          setJobCategoriesToMids({
            category: categoryCode,
            jobName,
            mid: objectId,
            name,
          });

          const newAnnotation = {
            ...annotation,
            categories: [{ name: categoryCode }],
            jobName,
          };
          dispatch(changeAnnotationClassVideo(newAnnotation));
        });
      }
      removeAllSelectedObjectIds();
    });
  };
};

const handleStartRelation = (payload: StartRelationPayload): AppThunk => {
  return (dispatch, getState) => {
    const {
      isImageProject,
      job,
      jobName,
      mid,
      responses,
      selectedCategory,
      creatingOrEditingObjectId,
      jsonInterface,
    } = payload;

    const mlTask = isImageProject
      ? MachineLearningTask.OBJECT_RELATION
      : MachineLearningTask.NAMED_ENTITIES_RELATION;

    const newAnnotation = isImageProject
      ? {
          categories: selectedCategory ? [{ name: selectedCategory.name }] : [],
          endObjects: [],
          jobName,
          mid: creatingOrEditingObjectId,
          mlTask,
          startObjects: [{ mid }],
        }
      : {
          categories: selectedCategory ? [{ name: selectedCategory.name }] : [],
          endEntities: [],
          jobName,
          mid: creatingOrEditingObjectId,
          mlTask,
          startEntities: [{ mid }],
        };

    if (jobName && selectedCategory) {
      dispatch(
        addAnnotation({
          annotation: newAnnotation,
          mlTask,
          noHistory: true,
        }),
      );
      const selectedRelation = selectedCategory?.name;
      const midsToHide = getMidsToHideWhenSelectingRelation(
        selectedRelation,
        job,
        responses,
        END_ENTITIES_STEP,
        [{ mid }],
      );
      labelInterfaceUpdateField({
        path: 'hiddenObjectIds',
        value: midsToHide,
      });
    } else {
      labelInterfaceUpdateField({
        path: 'temporaryAnnotations',
        value: [newAnnotation],
      });
      const availableClasses = availableClassesToChange({
        allowCurrentCategory: true,
        annotations: [newAnnotation],
        jsonInterface,
      });
      if (availableClasses.length === 1) {
        const newAnnotationFilledWithCategoryAndJobName = {
          ...newAnnotation,
          categories: [{ name: availableClasses[0].categoryCode }],
          jobName: availableClasses[0].jobName,
        };

        dispatch(
          addAnnotation({
            annotation: newAnnotationFilledWithCategoryAndJobName,
            mlTask,
            noHistory: true,
          }),
        );
        dispatch(
          setSelectedCategory(availableClasses[0].jobName, availableClasses[0].categoryCode),
        );
        const midsToHide = getMidsToHideWhenSelectingRelation(
          newAnnotationFilledWithCategoryAndJobName.categories?.[0].name,
          jsonInterface.jobs?.[availableClasses[0].jobName],
          jobsResponses(getState()),
          END_ENTITIES_STEP,
          [
            {
              mid:
                mlTask === MachineLearningTask.OBJECT_RELATION
                  ? (newAnnotationFilledWithCategoryAndJobName as ObjectRelation).startObjects?.[0]
                      ?.mid
                  : (newAnnotationFilledWithCategoryAndJobName as EntityRelation).startEntities?.[0]
                      ?.mid,
            },
          ],
        );
        labelInterfaceUpdateField({
          path: 'hiddenObjectIds',
          value: midsToHide,
        });
      }
    }
  };
};

const handleEndRelation = (payload: EndRelationPayload): AppThunk => {
  return dispatch => {
    const { isImageProject, jobName, mid, relationAnnotation } = payload;
    const newAnnotation = isImageProject
      ? {
          ...relationAnnotation,
          endObjects: ((relationAnnotation as ObjectRelation)?.endObjects ?? []).concat({ mid }),
        }
      : {
          ...relationAnnotation,
          endEntities: ((relationAnnotation as EntityRelation)?.endEntities ?? []).concat({ mid }),
        };
    const mlTask = isImageProject
      ? MachineLearningTask.OBJECT_RELATION
      : MachineLearningTask.NAMED_ENTITIES_RELATION;
    dispatch(
      updateResponseForAnnotations({
        annotations: [{ ...newAnnotation, jobName, mlTask }],
        mlTask,
      }),
    );
  };
};

type RelationStep = 'START_ENTITIES_STEP' | 'END_ENTITIES_STEP';

const getRelationObjectType = (
  isImageProject: boolean,
  relationStep: RelationStep,
): RelationObjectType => {
  if (relationStep === START_ENTITIES_STEP)
    return isImageProject ? RelationObjectType.START_OBJECTS : RelationObjectType.START_ENTITIES;
  return isImageProject ? RelationObjectType.END_OBJECTS : RelationObjectType.END_ENTITIES;
};

type IsSelectingWrongObjectForRelationArgs = {
  annotation: KiliAnnotation;
  dispatch: ThunkDispatch<Readonly<State>, unknown, Action<string>>;
  isImageProject: boolean;
  job: Job;
  mid: string;
  relationAnnotation?: ObjectRelation | EntityRelation;
  relationStep: RelationStep;
  selectedCategory: AnnotationCategory;
};

const isSelectingWrongObjectForRelation = ({
  dispatch,
  annotation,
  job,
  mid,
  isImageProject,
  relationAnnotation,
  selectedCategory,
  relationStep,
}: IsSelectingWrongObjectForRelationArgs) => {
  const allowedCategories = job?.content?.categories ?? {};
  const categoryCode = getCategoryCodeFromAnnotation(annotation);
  const relationObjectType = getRelationObjectType(isImageProject, relationStep);
  const allowedObjects = getAllowedObjects(allowedCategories, selectedCategory, relationObjectType);
  if (!allowedObjects.includes(ANY_RELATION_VALUE) && allowedObjects.indexOf(categoryCode) === -1) {
    dispatch(addNotification(notificationMessageAllowed(allowedObjects, false)));
    return true;
  }
  if (relationAnnotation && relationStep === END_ENTITIES_STEP) {
    if (isImageProject) {
      const objectRelationAnnotation = relationAnnotation as ObjectRelation;
      if (objectRelationAnnotation.endObjects.find(ann => ann.mid === mid)) {
        dispatch(addNotification(notificationMessageNoDuplicates(false)));
        return true;
      }
    } else {
      const nerRelationAnnotation = relationAnnotation as EntityRelation;
      if (nerRelationAnnotation.endEntities.find(ann => ann.mid === mid)) {
        dispatch(addNotification(notificationMessageNoDuplicates(true)));
        return true;
      }
    }
  }
  return false;
};

export const handleRelation = (mid: string): AppThunk => {
  return async (dispatch, getState) => {
    const state = getState();

    const { creatingOrEditingObjectId } = useStore.getState().labelInterface;

    const responses = jobsResponses(state);

    const annotation = getAnnotationFromMid(mid, responses);
    if (!annotation) throw new Error('Annotation not found');

    const inputType = projectInputType(state);
    const isImageProject = inputType === InputType.IMAGE;

    const currentMlTask = jobsCurrentMlTask(state);
    const currentJobName = jobCurrentJobName(state);
    const relationAnnotation = (
      responses?.[currentMlTask]?.[currentJobName] as RelationAnnotations | undefined
    )?.annotations?.find(ann => ann.mid === creatingOrEditingObjectId);
    const relationStep = !relationAnnotation ? START_ENTITIES_STEP : END_ENTITIES_STEP;

    const jsonInterface = projectJsonInterface(state);

    const selectedCategory = jobCurrentCategories(state)?.[0];

    const job = jsonInterface?.jobs?.[currentJobName];

    if (
      currentJobName &&
      isSelectingWrongObjectForRelation({
        annotation,
        dispatch,
        isImageProject,
        job,
        mid,
        relationAnnotation,
        relationStep,
        selectedCategory,
      })
    )
      return;

    if (relationStep === START_ENTITIES_STEP && creatingOrEditingObjectId) {
      dispatch(
        handleStartRelation({
          creatingOrEditingObjectId,
          isImageProject,
          job,
          jobName: currentJobName,
          jsonInterface,
          mid,
          responses,
          selectedCategory,
        }),
      );
      replaceAllWithSelectedObjectId({ mid: creatingOrEditingObjectId });
    }

    if (relationStep === END_ENTITIES_STEP && relationAnnotation) {
      dispatch(
        handleEndRelation({
          isImageProject,
          jobName: currentJobName,
          mid,
          relationAnnotation,
        }),
      );
    }
  };
};

import create, { type StateCreator } from 'zustand';
import { devtools, type DevtoolsOptions, persist, subscribeWithSelector } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import {
  createLabelPdfSlice,
  type LabelPdfSlice,
  type LabelPdfSliceValues,
} from '@/zustand/label-pdf';

import { type AssetLockSlice, type AssetLockSliceValues, createAssetLockSlice } from './asset-lock';
import { createAssetsSlice } from './assets';
import { type AssetsSlice, type AssetsSliceValues } from './assets/types';
import { createHelpersSlice, type HelpersSlice } from './helpers/index';
import { createIssuesSlice } from './issues';
import { type IssuesSlice, type IssuesSliceValues } from './issues/types';
import {
  createLabelFrameSlice,
  type LabelFrameSlice,
  type LabelFrameSliceValues,
} from './label-frame';
import {
  createLabelImageSemanticSlice,
  type LabelImageSemanticSlice,
  type LabelImageSemanticSliceValues,
} from './label-image-semantic';
import {
  createLabelInterfaceSlice,
  type LabelInterfaceSlice,
  type LabelInterfaceSliceValues,
} from './label-interface';
import {
  createLabelRichTextSlice,
  type LabelRichTextSlice,
  type LabelRichTextSliceValues,
} from './label-richtext';
import { createLabelSaveSlice, type LabelSaveSlice, type LabelSaveSliceValues } from './label-save';
import { migratePersistedState } from './migrate';
import {
  createProjectListSlice,
  type ProjectListSlice,
  type ProjectListSliceValues,
} from './project-list';
import { createProjectQueueSlice } from './project-queue';
import { type ProjectQueueSlice, type ProjectQueueSliceValues } from './project-queue/types';
import { createRegisterSlice } from './register';
import { type RegisterSlice, type RegisterSliceValues } from './register/types';
import {
  type SplitLabelSlice,
  type SplitLabelSliceValues,
  createSplitLabelSlice,
} from './split-label';
import { createTopBarSlice, type TopBarSlice, type TopBarSliceValues } from './top-bar';

import { shouldAddDevTools } from '../config';

export type ZustandStoreValues = {
  assetLock: AssetLockSliceValues;
  assets: AssetsSliceValues;
  issues: IssuesSliceValues;
  labelFrame: LabelFrameSliceValues;
  labelImageSemantic: LabelImageSemanticSliceValues;
  labelInterface: LabelInterfaceSliceValues;
  labelPdf: LabelPdfSliceValues;
  labelRichText: LabelRichTextSliceValues;
  labelSave: LabelSaveSliceValues;
  projectList: ProjectListSliceValues;
  projectQueue: ProjectQueueSliceValues;
  register: RegisterSliceValues;
  splitLabel: SplitLabelSliceValues;
  topBar: TopBarSliceValues;
};

export type ZustandStore = {
  assetLock: AssetLockSlice;
  assets: AssetsSlice;
  helpers: HelpersSlice;
  issues: IssuesSlice;
  labelFrame: LabelFrameSlice;
  labelImageSemantic: LabelImageSemanticSlice;
  labelInterface: LabelInterfaceSlice;
  labelPdf: LabelPdfSlice;
  labelRichText: LabelRichTextSlice;
  labelSave: LabelSaveSlice;
  projectList: ProjectListSlice;
  projectQueue: ProjectQueueSlice;
  register: RegisterSlice;
  splitLabel: SplitLabelSlice;
  topBar: TopBarSlice;
};

export type ZustandSlice<T> = StateCreator<
  ZustandStore,
  [
    ['zustand/devtools', never],
    ['zustand/subscribeWithSelector', never],
    ['zustand/immer', never],
    ['zustand/persist', unknown],
  ],
  [],
  T
>;

const devtoolsOptions: DevtoolsOptions & { trace: boolean } = {
  enabled: shouldAddDevTools,
  name: 'zustand',
  serialize: {
    options: {
      map: true,
      set: true,
    },
  },
  trace: true,
};

export const useStore = create<ZustandStore>()(
  devtools(
    subscribeWithSelector(
      immer(
        persist(
          (...props) => ({
            assetLock: createAssetLockSlice(...props),
            assets: createAssetsSlice(...props),
            helpers: createHelpersSlice(...props),
            issues: createIssuesSlice(...props),
            labelFrame: createLabelFrameSlice(...props),
            labelImageSemantic: createLabelImageSemanticSlice(...props),
            labelInterface: createLabelInterfaceSlice(...props),
            labelPdf: createLabelPdfSlice(...props),
            labelRichText: createLabelRichTextSlice(...props),
            labelSave: createLabelSaveSlice(...props),
            projectList: createProjectListSlice(...props),
            projectQueue: createProjectQueueSlice(...props),
            register: createRegisterSlice(...props),
            splitLabel: createSplitLabelSlice(...props),
            topBar: createTopBarSlice(...props),
          }),
          {
            merge: (persistedState, currentState) => {
              const persistedZustandStore = persistedState as ZustandStore;
              return {
                ...currentState,
                labelInterface: {
                  ...currentState.labelInterface,
                  settings: persistedZustandStore.labelInterface.settings,
                },
              };
            },
            migrate: (persistedState, version) => {
              const persistedZustandStore = persistedState as ZustandStore;
              migratePersistedState(persistedZustandStore, version);
              return persistedZustandStore;
            },
            name: 'zustand',
            partialize: state => ({
              labelInterface: {
                settings: state.labelInterface.settings,
              },
            }),
            version: 1,
          },
        ),
      ),
    ),
    devtoolsOptions,
  ),
);

export const {
  assetLock: { updateField: assetLockUpdateField },
  assets: { updateAssetField },
  labelInterface: {
    updateField: labelInterfaceUpdateField,
    removeSelectedObjectId,
    removeSelectedObjectIds,
    addSelectedObjectId,
    addSelectedObjectIds,
    generateNewCreatingOrEditingObjectId,
    removeAllSelectedObjectIds,
    replaceAllWithSelectedObjectId,
    toggleSelectedObjectId,
  },
  labelFrame: { updateField: labelFrameUpdateField },
  labelSave: { updateField: labelSaveUpdateField },
  helpers: { updateField },
  topBar,
  projectList: { updateField: projectListUpdateField },
  projectQueue: {
    prioritizeAssets,
    resetProjectInfos,
    selectOrUnselectAllAssets,
    selectOrUnselectAsset,
    updateSelectionState,
  },
  register,
  issues: { updateField: issuesUpdateField },
  splitLabel: {
    addAnnotationModification,
    addAnnotationModifications,
    addAnnotationValueModification,
    addAnnotationValueModifications,
    initialize,
    saveFailure,
    saveSuccess,
    moveNextToPendingModifications,
  },
} = useStore.getState();

/* eslint-disable default-case, no-case-declarations */
import { type JsonCategory as Category } from '@kili-technology/types';
import { type Location } from 'history';
import _get from 'lodash/get';

import {
  type AssetWhere,
  IssueType,
  type IssueWhere,
  LabelType,
  type LabelWhere,
} from '../../__generated__/globalTypes';
import {
  SCOPE_OF_LABELS_SEARCHED,
  SEARCH,
} from '../../pages/projects/view/dataset/ProjectDataset/constants';

const urlSearchToObject = (
  search?: string,
): Record<string, string | string[] | number | Record<string, string[]> | boolean | null> => {
  const params = new URLSearchParams(search);
  const result: Record<string, string | string[] | number | Record<string, string[]>> = {};
  for (const param of params) {
    // each 'entry' is a [key, value] tupple
    const [key, value] = param;
    if (key.includes('[') && key.includes(']')) {
      const splitted = key.split('[');
      const baseKey = splitted[0];
      const propertyKey = splitted[1].split(']')[0];
      if (!result[baseKey]) {
        result[baseKey] = {};
      }
      if (!(result[baseKey] as Record<string, string[]>)[propertyKey]) {
        (result[baseKey] as Record<string, string[]>)[propertyKey] = [];
      }
      (result[baseKey] as Record<string, string[]>)[propertyKey].push(value);
    } else {
      result[key] = value;
    }
  }
  return result;
};

const parseList = (value: string): string[] => value.split(',');

const parseBoolean = (value: string): boolean => value === 'true';

const parseType = (value: string): IssueType | null => {
  switch (value.toLowerCase()) {
    case 'issue':
      return IssueType.ISSUE;
    case 'question':
      return IssueType.QUESTION;
    default:
      return null;
  }
};

const getMetadataForDynamicRange = (key: string, value: string, matcher: RegExp) => {
  const [, metadataName] = key.match(matcher) as [string, string];

  return {
    key: metadataName,
    value: parseFloat(value),
  };
};

export const parsedQueryToAssetWhere = (location: Location): AssetWhere => {
  const search = _get(location, SEARCH, '').substring(1);
  const searchObject = urlSearchToObject(search);

  const assetWhere: AssetWhere & { issue: IssueWhere; label: LabelWhere } = {
    issue: {},
    label: {},
    metadata: _get(searchObject, 'metadata', {}) as Record<string, string[]>,
  };

  const params = new URLSearchParams(search);
  for (const [key, value] of params) {
    switch (true) {
      case /^assigneeIn$/.test(key):
        assetWhere.assigneeIn = parseList(value);
        break;
      case /^assigneeNotIn$/.test(key):
        assetWhere.assigneeNotIn = parseList(value);
        break;
      case /^consensusMarkGte$/.test(key):
        assetWhere.consensusMarkGte = parseFloat(value);
        break;
      case /^consensusMarkLte$/.test(key):
        assetWhere.consensusMarkLte = parseFloat(value);
        break;
      case /^createdAtGte$/.test(key):
        assetWhere.createdAtGte = value;
        break;
      case /^createdAtLte$/.test(key):
        assetWhere.createdAtLte = value;
        break;
      case /^externalIdIn$/.test(key):
        assetWhere.externalIdIn = parseList(value);
        break;
      case /^honeypotMarkGte$/.test(key):
        assetWhere.label.honeypotMarkGte = parseFloat(value);
        assetWhere.label.typeIn = SCOPE_OF_LABELS_SEARCHED;
        break;
      case /^honeypotMarkLte$/.test(key):
        assetWhere.label.honeypotMarkLte = parseFloat(value);
        assetWhere.label.typeIn = SCOPE_OF_LABELS_SEARCHED;
        break;
      case /^inferenceMarkGte$/.test(key):
        assetWhere.inferenceMarkGte = parseFloat(value);
        break;
      case /^inferenceMarkLte$/.test(key):
        assetWhere.inferenceMarkLte = parseFloat(value);
        break;
      case /^issueStatus$/.test(key):
        assetWhere.issue.status = value;
        break;
      case /^issueType$/.test(key):
        assetWhere.issue.type = parseType(value);
        break;
      case /^labelCreatedAtGte$/.test(key):
        assetWhere.label.createdAtGte = value;
        assetWhere.label.typeIn = SCOPE_OF_LABELS_SEARCHED;
        break;
      case /^labelCreatedAtLte$/.test(key):
        assetWhere.label.createdAtLte = value;
        assetWhere.label.typeIn = SCOPE_OF_LABELS_SEARCHED;
        break;
      case /^labelerIn$/.test(key):
        assetWhere.label.labelerIn = parseList(value);
        break;
      case /^labelerNotIn$/.test(key):
        assetWhere.label.labelerNotIn = parseList(value);
        break;
      case /^metadata-(.*)-min$/.test(key):
        const minRange = getMetadataForDynamicRange(key, value, /^metadata-(.*)-min$/);
        if (assetWhere.metadata[minRange.key]) {
          assetWhere.metadata[minRange.key][0] = minRange.value;
        } else assetWhere.metadata[minRange.key] = [minRange.value, minRange.value];
        break;
      case /^metadata-(.*)-max$/.test(key):
        const maxRange = getMetadataForDynamicRange(key, value, /^metadata-(.*)-max$/);
        if (assetWhere.metadata[maxRange.key]) {
          assetWhere.metadata[maxRange.key][1] = maxRange.value;
        } else assetWhere.metadata[maxRange.key] = [maxRange.value, maxRange.value];
        break;
      case /^metadata$/.test(key):
        assetWhere.metadata = value;
        break;
      case /^metadata\[(.*)\]$/.test(key):
        let formattedValue: string;
        try {
          const parsedValue = JSON.parse(value);
          if (typeof parsedValue !== 'object') break; // case of numbers
          formattedValue = parsedValue;
        } catch {
          break;
        }

        const metadataKey = key.match(/^metadata\[(.*)\]$/)?.[1];
        if (metadataKey === undefined)
          throw new Error('Metadata key should be defined when metadata is set');

        if (assetWhere.metadata[metadataKey]) {
          const previousValue = assetWhere.metadata[metadataKey];

          if (Array.isArray(previousValue)) {
            assetWhere.metadata[metadataKey] = [...previousValue, formattedValue];
          } else assetWhere.metadata[metadataKey] = [previousValue, formattedValue];
        } else assetWhere.metadata[metadataKey] = formattedValue;
        break;
      case /^predictedSearch$/.test(key):
        assetWhere.label.search = value;
        assetWhere.label.typeIn = [LabelType.INFERENCE, LabelType.PREDICTION];
        break;
      case /^reviewerIn$/.test(key):
        assetWhere.label.reviewerIn = parseList(value);
        break;
      case /^reviewerNotIn$/.test(key):
        assetWhere.label.reviewerNotIn = parseList(value);
        break;
      case /^reviewScoreGte$/.test(key):
        assetWhere.label.reviewScoreGte = parseFloat(value);
        break;
      case /^reviewScoreLte$/.test(key):
        assetWhere.label.reviewScoreLte = parseFloat(value);
        break;
      case /^search$/.test(key):
        assetWhere.latestLabel = { search: value };
        break;
      case /^transcriptionSearch$/.test(key):
        if (value) {
          assetWhere.latestLabel = {
            ...(assetWhere.latestLabel ?? {}),
            transcriptionSearch: value,
          };
        }
        break;
      case /^transcriptionPredictedSearch$/.test(key):
        if (value) {
          assetWhere.label = {
            ...(assetWhere.label ?? {}),
            transcriptionSearch: value,
            typeIn: [LabelType.INFERENCE, LabelType.PREDICTION],
          };
        }
        break;
      case /^skipped$/.test(key):
        assetWhere.skipped = parseBoolean(value);
        break;
      case /^statusIn$/.test(key):
        assetWhere.statusIn = parseList(value);
        break;
      case /^sort$/.test(key):
        assetWhere.sort = value;
        break;
      case /^typeIn$/.test(key):
        assetWhere.label.typeIn = parseList(value);
        break;
    }
  }
  return assetWhere;
};

export const filterCategories = (categories: Category[], search: string): Category[] =>
  search
    ? categories.filter(category => category.name.toLowerCase().includes(search.toLowerCase()))
    : categories;

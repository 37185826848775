import { type JobResponse, type JsonResponse } from '@kili-technology/types';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import fromEntries from 'object.fromentries';
import { createSelector } from 'reselect';

import { extractLabelsToDisplayInTable } from './helpers';
import { type AssetLabelState } from './types';

import {
  type Asset,
  InputType,
  type Label,
  LabelType,
  type User,
} from '../../__generated__/globalTypes';
import { type PropsType as ThumbnailPropsType } from '../../components/ThumbnailsSelect/Thumbnail/Thumbnail';
import { type PropsType as ThumbnailImagePropsType } from '../../components/ThumbnailsSelect/Thumbnail/ThumbnailImage/ThumbnailImage';
import { getResponsesToSet } from '../../services/jobs/setResponse';
import { getFullName } from '../../services/user/format';
import { printAuthorNameOrModelNameWhenItExists, responseFromResponsesToSet } from '../helpers';
import {
  getImageAnnotations,
  getClassificationJobs,
  getImageRelationAnnotations,
  getImageRotation,
  getObjectDetectionJobs,
  getObjectRelationJobs,
} from '../jobs/helpers';
import { initialState as jobsInitialState } from '../jobs/initialState';
import { type ResponsesTasks } from '../jobs/types';
import { projectInputType, projectJobs, userId as selectUserId } from '../selectors';
import { type State } from '../types';

const assetLabelState = (state: State): AssetLabelState => _get(state, 'assetLabel');

export const selectSelectedAssetId = createSelector(
  [assetLabelState],
  assetLabel => assetLabel.asset?.id,
);

const assetLabelLabel = createSelector([assetLabelState], assetLabel => assetLabel?.label || {});

export const assetLabelLabelId = createSelector([assetLabelLabel], label => _get(label, 'id'));

export const assetLabelLabelAuthor = createSelector(
  [assetLabelLabel],
  label => _get(label, 'author', undefined) as User,
);

export const assetLabelLabelAuthorOrModelName = createSelector([assetLabelLabel], label =>
  printAuthorNameOrModelNameWhenItExists(label as Label),
);

export const assetLabelLabelAuthorName = createSelector([assetLabelLabelAuthor], author =>
  getFullName(_get(author, 'firstname'), _get(author, 'lastname')),
);

export const assetLabelCurrentAuthorId = createSelector([assetLabelLabelAuthor], author =>
  _get(author, 'id', ''),
);

export const assetLabelCurrentAssetId = createSelector(
  [assetLabelState],
  assetLabel => _get(assetLabel, 'asset.id', '') as Asset['id'],
);

export const assetLabelCurrentLabelId = (state: State): Label['id'] =>
  _get(state, 'assetLabel.label.id');

export const assetLabelStatus = (state: State): Asset['status'] =>
  _get(state, 'assetLabel.asset.status', '');

export const assetLabelExternalId = (state: State): string =>
  _get(state, 'assetLabel.asset.externalId', '');

export const assetLabelCurrentIndex = createSelector(
  [assetLabelState],
  ({ asset, viewedAssetIds }) => {
    const assetId = asset?.id;

    return assetId === undefined || !viewedAssetIds.includes(assetId)
      ? 0
      : viewedAssetIds.indexOf(assetId);
  },
);

export const assetLabelViewedAssetIds = createSelector(
  [assetLabelState],
  assetLabel => assetLabel?.viewedAssetIds ?? [],
);

export const selectNonSubmittedViewedAssetIds = createSelector(
  [assetLabelState],
  assetLabel => assetLabel?.nonSubmittedViewedAssetIds ?? [],
);

export const selectAssetLabelJsonMetadata = createSelector(
  [assetLabelState],
  assetLabel => _get(assetLabel, 'asset.jsonMetadata', null) as Asset['jsonMetadata'],
);

export const assetLabelMetadata = createSelector(
  [selectAssetLabelJsonMetadata],
  assetLabelJsonMetadata => {
    const jsonMetadata = assetLabelJsonMetadata || '{}';
    try {
      return JSON.parse(jsonMetadata);
    } catch {
      return jsonMetadata;
    }
  },
);

export const assetLabelSecondsToLabel = (state: State): Label['secondsToLabel'] =>
  _get(state, 'assetLabel.label.secondsToLabel', 0);

export const assetLabelLabelType = (state: State): Label['labelType'] =>
  _get(state, 'assetLabel.label.labelType', LabelType.DEFAULT);

const getAssetFromOwnPropsImage = (state: State, ownProps: ThumbnailImagePropsType): Asset =>
  ownProps?.asset;
const getAssetFromOwnProps = (state: State, ownProps: ThumbnailPropsType): Asset => ownProps?.asset;

export const getAssetMetadataProcessingParameters = (asset: Asset) => {
  const metadata = _get(asset, 'jsonMetadata');
  if (_isEmpty(asset) || !metadata) {
    return {};
  }
  try {
    const metadataParsed = JSON.parse(metadata);
    return metadataParsed?.processingParameters ?? {};
  } catch {
    return {};
  }
};

export const assetMetadataProcessingParameters = createSelector(
  [getAssetFromOwnProps],
  getAssetMetadataProcessingParameters,
);

export const assetLatestLabel = createSelector(
  [getAssetFromOwnPropsImage, selectUserId],
  (asset, userId) => {
    if (_isEmpty(asset)) {
      return {};
    }
    const orderedUniqLabels = extractLabelsToDisplayInTable(asset, userId);
    const latestLabel = orderedUniqLabels?.[0];
    return latestLabel;
  },
);

export const assetLatestResponse = createSelector(
  [assetLatestLabel, projectJobs, projectInputType, assetLabelLabelType],
  (label, jobs, inputType, labelType) => {
    const jobParsed: JobResponse = JSON.parse(_get(label, 'jsonResponse', '{}'));
    const isVideo = inputType === InputType.VIDEO;
    if (isVideo) {
      return fromEntries(
        Object.entries(jobParsed).map(([key, value]) => {
          const keyResponsesToSet = responseFromResponsesToSet(
            getResponsesToSet(jobs, labelType, value),
            jobsInitialState,
          );
          return [key, keyResponsesToSet];
        }),
      );
    }
    const jsonParsed: JsonResponse = JSON.parse(_get(label, 'jsonResponse', '{}'));
    const responsesToSet = getResponsesToSet(jobs, labelType, jsonParsed);
    return responseFromResponsesToSet(responsesToSet, jobsInitialState) as ResponsesTasks;
  },
);

export const assetLatestResponseLabelType = createSelector([assetLatestLabel], label => {
  return (label as Label)?.labelType ?? LabelType.DEFAULT;
});

export const assetImageAnnotations = createSelector([assetLatestResponse], response =>
  getImageAnnotations(getObjectDetectionJobs(response)),
);

export const assetClassificationResponses = createSelector([assetLatestResponse], response =>
  getClassificationJobs(response),
);

export const assetImageRelationAnnotations = createSelector([assetLatestResponse], response =>
  getImageRelationAnnotations(getObjectRelationJobs(response)),
);

export const assetImageRotation = createSelector([assetLatestResponse], response =>
  getImageRotation(response),
);

export const selectReviewedAssets = (state: State) => state.assetLabel.reviewedAssets;

export const selectLabeledAssets = (state: State) => state.assetLabel.labeledAssets;

import { InputType } from '@/__generated__/globalTypes';
import { projectInputType } from '@/redux/selectors';
import { isFlagActivated } from '@/services/flags/useFlag';
import { store } from '@/store';

/**
 * Whereas the current project is using the split feature.
 */
export function doesProjectUseSplit(): boolean {
  const inputType = projectInputType(store.getState());

  if ([InputType.LLM_RLHF, InputType.LLM_INSTR_FOLLOWING].includes(inputType)) {
    return true;
  }
  if (inputType === InputType.VIDEO) {
    return isFlagActivated('lab_splitted_json_response');
  }
  return false;
}
